import React from 'react';
import styled, { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import { useCurrentTheme } from 'app/shared/theme';
import {
  withBorderCSS,
  WithBorderProps,
  withDimensionsCSS,
  WithDimensionsProps,
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';

export interface ImageProps
  extends React.HTMLAttributes<HTMLPictureElement>,
    WithSpacingProps,
    WithDimensionsProps,
    WithBorderProps {
  src: string;
  srcMobile?: string;
  srcTablet?: string;
  srcSmallDesktop?: string;
  srcLargeDesktop?: string;
  grayscale?: boolean;
  display?: string;
  alt?: string;
}

interface PictureProps extends WithSpacingProps, WithBorderProps {
  display?: string;
}

const StyledPicture = styled.picture<PictureProps>`
  ${({ display = 'grid' }) => css`
    ${withSpacingCSS};
    ${withBorderCSS};
    ${withDimensionsCSS};

    display: ${display};
  `}
`;

const StyledImage = styled.img<{
  grayscale: boolean;
  borderRadius?: string;
  height?: string;
  width?: string;
}>`
  object-fit: cover;
  object-position: top;

  ${({ grayscale, borderRadius, height = 'inherit', width = '100%' }) => css`
    height: ${height};
    width: ${width};
    ${grayscale && 'filter: grayscale(100%);'}
    ${borderRadius && `border-radius: ${borderRadius};`}
  `}
`;

const StyledSource = styled.source`
  display: none;
`;

export const Image: React.FC<ImageProps> = ({
  src,
  srcMobile,
  srcTablet,
  srcSmallDesktop,
  srcLargeDesktop,
  grayscale = false,
  borderRadius,
  alt,
  h,
  w,
  ...props
}) => {
  const { breakPoints }: CommonTheme = useCurrentTheme();

  return (
    <StyledPicture {...props}>
      <StyledSource
        srcSet={srcLargeDesktop ? srcLargeDesktop : src}
        media={`(min-width: ${breakPoints.largeDesktop}px)`}
      />
      <StyledSource
        srcSet={srcSmallDesktop ? srcSmallDesktop : src}
        media={`(min-width: ${breakPoints.smallDesktop}px)`}
      />
      <StyledSource
        srcSet={srcTablet ? srcTablet : src}
        media={`(min-width: ${breakPoints.tablet}px)`}
      />
      <StyledSource
        srcSet={srcMobile ? srcMobile : src}
        media={`(min-width: ${breakPoints.mobile}px)`}
      />
      <StyledImage
        src={src}
        alt={alt}
        sizes="100vw"
        grayscale={grayscale}
        borderRadius={borderRadius}
        height={h}
        width={w}
      />
    </StyledPicture>
  );
};
