import gql from 'graphql-tag';

export const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags($flags: [String!]!, $flipperId: String) {
    featureFlags(flags: $flags, flipperId: $flipperId) {
      name
      featureFlagEnabled
      experimentEligible
    }
  }
`;
