import { FormikProps } from 'formik';

import { City } from './cities';
import { User } from './users';

export interface SignUpSuccessParams {
  user: User;
  competitionDataSharingOptIn?: boolean;
  userHomeCity?: City;
  isNewAccount?: boolean;
}
export type OnSignUpSuccess = (
  signUpSuccessParams?: SignUpSuccessParams
) => void;

export enum ModalStep {
  TICKET_INFO = 'ticket-info',
  CUSTOMER_LEAD = 'customer-lead',
  COMPLETE_ACCOUNT = 'complete-account',
  CREATE_ACCOUNT = 'create-account',
  SMS_OPT_IN = 'sms-sign-up',
}

export interface RenderCompetitionOptInProps {
  formikProps: FormikProps<any>;
  authType: string;
}

export type RenderCompetitionOptIn = React.FC<RenderCompetitionOptInProps>;

export type AuthFormType = 'signIn' | 'signUp';
