import { useEffect, useState } from 'react';
import { AnalyticsBrowser, ID } from '@segment/analytics-next';

export const useAnonymousId = (analytics: AnalyticsBrowser) => {
  const [anonymousId, setAnonymousId] = useState<ID>();

  useEffect(() => {
    async function initializeAnonymousId() {
      const segmentUser = await analytics.user();
      setAnonymousId(segmentUser.anonymousId());
    }
    initializeAnonymousId();
  }, [analytics]);

  return anonymousId;
};
