import gql from 'graphql-tag';

const SESSION_USER_FIELDS = gql`
  fragment SessionUserFields on User {
    id
    email
    firstName
    lastName
    birthYear
    birthYear
    howHeardAbout
    gender
    billingRegion
    homeCity {
      id
      title
      cachedSlug
      imageUrl
      country {
        id
        countryCode
      }
    }
    locale
    mobile {
      countryCodeIso3166
      numberBody
    }
    optInFanMarketingSmses
    isArtist
    isInternal
    acknowledgedGdprAgreementAt
    acknowledgedCuratorAgreementAt
    acknowledgedCuratorAgreement2At
    declinedCuratorAgreement2At
    optOutFanMarketingEmails
    __typename
  }
`;

export const GET_SESSION = gql`
  query GetSession {
    session {
      user {
        ...SessionUserFields
      }
      loggedIn
      magicTokenAuthentication
      userIpGeolocation {
        latitude
        longitude
        countryCode
        continentCode
        geoIpDataStoredAt
      }
      message
      messageMoreInfoLink
      messageMoreInfoText
      visa {
        cardId
        cardLast4
        eligible
        eligibilityLevel
        visaVerifiedAt
        cardType
      }
      visitorType
      closestCitiesCache {
        closestCityIds
        closestCitiesStoredAt
      }
    }
  }
  ${SESSION_USER_FIELDS}
`;

export const GET_SESSION_WITH_PERMISSIONS = gql`
  query GetSessionWithPermissions($permissionContexts: [String]) {
    session(permissionContexts: $permissionContexts) {
      user {
        ...SessionUserFields
      }
      loggedIn
      magicTokenAuthentication
      userIpGeolocation {
        latitude
        longitude
        countryCode
        continentCode
        geoIpDataStoredAt
      }
      message
      messageMoreInfoLink
      messageMoreInfoText
      visa {
        cardId
        cardLast4
        eligible
        eligibilityLevel
        visaVerifiedAt
        cardType
      }
      visitorType
      closestCitiesCache {
        closestCityIds
        closestCitiesStoredAt
      }
      permissions {
        name
        resourceId
        resourceType
        associatedObjectResourceId
        associatedObjectResourceType
        resourceExtraData {
          curatorGroupType
        }
      }
    }
  }
  ${SESSION_USER_FIELDS}
`;
