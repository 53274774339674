import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { Typography } from 'app/shared/components/atoms';

interface Props {
  hasError: boolean;
  isSuccessful: boolean;
  isDisabled: boolean;
  children?: React.ReactNode;
  invertColors?: boolean;
  'data-qaid'?: string;
}

export const InputHelperText = ({
  children,
  hasError,
  isSuccessful,
  isDisabled,
  invertColors,
  'data-qaid': qaId = 'input-helper-text',
}: Props) => {
  const { colors } = useCurrentTheme();

  if (!children || children[0] === '') {
    return null;
  }

  const helperTextColor = () => {
    if (isDisabled && !isSuccessful) {
      return invertColors
        ? colors.primary.white.base
        : colors.primary.black.lightest;
    } else if (hasError) {
      return colors.messaging.error[invertColors ? 'light' : 'dark'];
    } else if (isSuccessful) {
      return colors.messaging.success[invertColors ? 'light' : 'dark'];
    }

    return invertColors ? colors.primary.white.base : colors.primary.black.base;
  };
  return (
    <Typography
      data-qaid={qaId}
      pt={1}
      fontSize="fontSize50"
      color={helperTextColor()}
    >
      {children}
    </Typography>
  );
};
