import React from 'react';

interface ErrorBoundaryProps {
  children?: React.ReactNode;
  ErrorComponent: () => JSX.Element;
  onLogError?: (error: any) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

// React does not have hooks for getDerivedStateFromError (or componentDidCatch)
// so we have to use an old-school component in order to get the functionality
export class ErrorBoundary extends React.PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public readonly state: ErrorBoundaryState = {
    hasError: false,
  };

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: any, _info: any) {
    const { onLogError } = this.props;
    // Supposedly info.componentStack contains a stack trace but couldn't get that to work, so not logging it
    onLogError && onLogError(error);
  }

  public render() {
    if (this.state.hasError) {
      const { ErrorComponent } = this.props;
      return <ErrorComponent />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
