import { css } from 'styled-components';

import { BreakPoints } from 'app/shared/theme/common/typings';

export const breakPoints: BreakPoints = {
  mobile: 0,
  tablet: 481,
  smallDesktop: 901,
  largeDesktop: 1601,
};

export const media = Object.keys(breakPoints).reduce((acc, label: string) => {
  acc[label] = (...args: any) => css`
    @media (min-width: ${breakPoints[label]}px) {
      ${css.apply(undefined, args)};
    }
  `;
  return acc;
}, {});
