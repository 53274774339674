import React from 'react';

import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { ParentMenu } from 'app/shared/typings/navbar';
import { Box, Typography } from 'app/shared/components/atoms';

interface ParentMenuItemMobileProps {
  parentMenuItem: ParentMenu;
  qaId: string;
  index: number;
  color: string;
}

export const ParentMenuItem: React.FC<ParentMenuItemMobileProps> = ({
  parentMenuItem,
  qaId,
  index,
  color,
}) => {
  const { isDesktop } = useIsBreakpointSize();

  if (
    (parentMenuItem.mobileOnly && isDesktop) ||
    parentMenuItem.childMenu.length === 0
  ) {
    return null;
  }

  return (
    <Box
      data-qaid={`${qaId}-parent-menu-item`}
      key={`${qaId}-parent-menu-item-${index}`}
      display="inline-block"
    >
      <Typography
        as="h2"
        fontWeight="medium"
        display="inline-block"
        textTransform="uppercase"
        color={color}
        data-qaid={`${qaId}-parent-menu-item-text-${index}`}
      >
        {parentMenuItem.title}
      </Typography>
    </Box>
  );
};
