import React from 'react';
import styled from 'styled-components';

import { ManualCSSColors, useCurrentTheme } from 'app/shared/theme';
import { socialIconsForAdminManualCSS } from 'app/shared/utils/socialIcons';
import { SocialUrlKey } from 'app/shared/typings';
import { StreamlineIcon } from 'app/shared/components/atoms/StreamlineIcon';
import { ReactComponent as CheckCircle } from 'icons/sofar-icons/checkCircle.svg';
import { ReactComponent as ArrowLeft } from 'icons/streamline-regular/arrows-diagrams/arrows/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'icons/streamline-regular/arrows-diagrams/arrows/arrow-right.svg';
import { ReactComponent as Archive } from 'icons/streamline-regular/content/archives/archive.svg';
import { ReactComponent as ArchiveRestore } from 'icons/streamline-regular/content/archives/archive-restore.svg';
import { ReactComponent as NotesBookText } from 'icons/streamline-regular/content/notes/notes-book-text.svg';
import { ReactComponent as AlertTriangle } from 'icons/streamline-regular/interface-essential/alerts/alert-triangle.svg';
import { ReactComponent as Bin1 } from 'icons/streamline-regular/interface-essential/delete/bin-1.svg';
import { ReactComponent as Pencil } from 'icons/streamline-regular/interface-essential/edit/pencil.svg';
import { ReactComponent as Checklist } from 'icons/streamline-regular/interface-essential/form-edition/checklist.svg';
import { ReactComponent as Check1 } from 'icons/streamline-regular/interface-essential/form-validation/check-1.svg';
import { ReactComponent as Close } from 'icons/streamline-regular/interface-essential/form-validation/close.svg';
import { ReactComponent as House } from 'icons/streamline-regular/interface-essential/home/house.svg';
import { ReactComponent as ListBullets } from 'icons/streamline-regular/interface-essential/lists/list-bullets.svg';
import { ReactComponent as Add } from 'icons/streamline-regular/interface-essential/remove-add/add.svg';
import { ReactComponent as AddCircle } from 'icons/streamline-regular/interface-essential/remove-add/add-circle.svg';
import { ReactComponent as SubtractCircle } from 'icons/streamline-regular/interface-essential/remove-add/subtract-circle.svg';
import { ReactComponent as SearchPlain } from 'icons/streamline-regular/interface-essential/search/search.svg';
import { ReactComponent as SearchCircle } from 'icons/streamline-regular/interface-essential/search/search-circle.svg';
import { ReactComponent as DownloadBottom } from 'icons/streamline-regular/internet-networks-servers/upload-download/download-bottom.svg';
import { ReactComponent as MusicNote2 } from 'icons/streamline-regular/music-audio/music/music-note-2.svg';
import { ReactComponent as Baggage } from 'icons/streamline-regular/travel/baggage/baggage.svg';

const actionIconStroke = (theme: any, disabled?: boolean) =>
  disabled ? theme.colors.blueSmoke : theme.colors.green600;

interface ActionIconWrapperProps {
  disabled?: boolean;
}

const ActionIconWrapper = styled.div<ActionIconWrapperProps>`
  ${(props) => (props.disabled ? '' : 'cursor: pointer;')}
`;

const IconWrapper = styled.span``;

interface BaseIconProps {
  icon: any;
  iconSize?: number;
  iconStrokeColor?: ManualCSSColors | 'none';
  iconHoverStrokeColor?: ManualCSSColors;
  iconFillColor?: ManualCSSColors | 'none';
  onClick?: () => void;
  disabled?: boolean;
  dataQaid: string;
}

const BaseIcon: React.FC<React.PropsWithChildren<BaseIconProps>> = ({
  icon,
  iconSize = 16,
  iconStrokeColor = 'green600',
  iconHoverStrokeColor = 'backToBlack',
  iconFillColor = 'none',
  onClick,
  disabled,
  dataQaid,
}) => {
  const theme = useCurrentTheme();

  return (
    <>
      {onClick ? (
        <ActionIconWrapper
          onClick={onClick}
          disabled={disabled}
          data-qaid={dataQaid}
        >
          <StreamlineIcon
            icon={icon}
            size={iconSize}
            stroke={actionIconStroke(theme, disabled)}
          />
        </ActionIconWrapper>
      ) : (
        <IconWrapper data-qaid={dataQaid}>
          <StreamlineIcon
            icon={icon}
            size={iconSize}
            stroke={theme.colors[iconStrokeColor] || iconStrokeColor}
            hoverStroke={
              theme.colors[iconHoverStrokeColor] || iconHoverStrokeColor
            }
            fill={theme.colors[iconFillColor] || iconFillColor}
          />
        </IconWrapper>
      )}
    </>
  );
};

interface IconProps {
  iconSize?: number;
  iconColor?: ManualCSSColors;
  iconHoverColor?: ManualCSSColors;
  onClick?: () => void;
  disabled?: boolean;
  dataQaid?: string;
  className?: string;
}

export const ViewIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  iconColor,
  onClick,
  disabled = false,
  dataQaid = 'view-icon',
}) => {
  return (
    <BaseIcon
      icon={NotesBookText}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const AddIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  iconColor,
  onClick,
  disabled = false,
  dataQaid = 'add-icon',
}) => {
  return (
    <BaseIcon
      icon={Add}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const EditIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  onClick,
  disabled = false,
  dataQaid = 'edit-icon',
}) => {
  return (
    <BaseIcon
      icon={Pencil}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const DeleteIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  onClick,
  disabled = false,
  dataQaid = 'delete-icon',
}) => {
  return (
    <BaseIcon
      icon={Bin1}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ArchiveIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  onClick,
  disabled = false,
  dataQaid = 'archive-icon',
}) => {
  return (
    <BaseIcon
      icon={Archive}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ArchiveRestoreIcon: React.FC<
  React.PropsWithChildren<IconProps>
> = ({
  iconSize = 16,
  onClick,
  disabled = false,
  dataQaid = 'archive-restore-icon',
}) => {
  return (
    <BaseIcon
      icon={ArchiveRestore}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ListIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  onClick,
  disabled = false,
  dataQaid = 'list-icon',
}) => {
  return (
    <BaseIcon
      icon={ListBullets}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ChecklistIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  iconColor = 'green600',
  onClick,
  disabled = false,
  dataQaid = 'checklist-icon',
}) => {
  return (
    <BaseIcon
      icon={Checklist}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ArrowLeftIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 14,
  onClick,
  disabled = false,
  dataQaid = 'arrow-left-icon',
}) => {
  return (
    <BaseIcon
      icon={ArrowLeft}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const ArrowRightIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 14,
  onClick,
  disabled = false,
  dataQaid = 'arrow-right-icon',
}) => {
  return (
    <BaseIcon
      icon={ArrowRight}
      iconSize={iconSize}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const SearchIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  onClick,
  dataQaid = 'search-icon',
}) => {
  return (
    <BaseIcon
      icon={SearchCircle}
      iconSize={20}
      onClick={onClick}
      dataQaid={dataQaid}
    />
  );
};

export const SearchPlainIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 20,
  iconColor = 'backToBlack',
  onClick,
  dataQaid = 'search-plain-icon',
}) => {
  return (
    <BaseIcon
      icon={SearchPlain}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      dataQaid={dataQaid}
    />
  );
};

export const DownloadIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  iconColor,
  onClick,
  disabled = false,
  dataQaid = 'download-icon',
}) => {
  return (
    <BaseIcon
      icon={DownloadBottom}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const CloseIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 15,
  iconColor = 'blueSmoke',
  dataQaid = 'close-icon',
}) => {
  return (
    <BaseIcon
      icon={Close}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      dataQaid={dataQaid}
    />
  );
};

export const YesIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  dataQaid = 'yes-icon',
}) => {
  return (
    <BaseIcon
      icon={Check1}
      iconSize={12}
      iconStrokeColor="green600"
      dataQaid={dataQaid}
    />
  );
};

export const NoIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  dataQaid = 'no-icon',
}) => {
  return (
    <BaseIcon
      icon={Close}
      iconSize={12}
      iconStrokeColor="blueSmoke"
      dataQaid={dataQaid}
    />
  );
};

export const ToggleOpenIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconHoverColor = undefined,
  dataQaid = 'toggle-open-icon',
}) => {
  return (
    <BaseIcon
      icon={AddCircle}
      iconSize={14}
      iconStrokeColor="green600"
      iconHoverStrokeColor={iconHoverColor}
      dataQaid={dataQaid}
    />
  );
};

export const ToggleCloseIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconHoverColor = undefined,
  dataQaid = 'toggle-close-icon',
}) => {
  return (
    <BaseIcon
      icon={SubtractCircle}
      iconSize={14}
      iconStrokeColor="green600"
      iconHoverStrokeColor={iconHoverColor}
      dataQaid={dataQaid}
    />
  );
};

export const AlertIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 38,
  dataQaid = 'alert-icon',
}) => {
  return (
    <BaseIcon
      icon={AlertTriangle}
      iconSize={iconSize}
      iconStrokeColor="redRedWine"
      dataQaid={dataQaid}
    />
  );
};

export const CheckIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  iconColor = 'backToBlack',
  dataQaid = 'check-icon',
}) => {
  return (
    <BaseIcon
      icon={Check1}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      dataQaid={dataQaid}
    />
  );
};

export const CheckCircleIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 24,
  iconColor = 'green600',
  dataQaid = 'check-circle-icon',
}) => {
  return (
    <BaseIcon
      icon={CheckCircle}
      iconSize={iconSize}
      iconStrokeColor="none"
      iconFillColor={iconColor}
      dataQaid={dataQaid}
    />
  );
};

export const MusicNoteIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  iconColor,
  onClick,
  disabled = false,
  dataQaid = 'music-note-icon',
}) => {
  return (
    <BaseIcon
      icon={MusicNote2}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const HouseIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  iconColor,
  onClick,
  disabled = false,
  dataQaid = 'house-icon',
}) => {
  return (
    <BaseIcon
      icon={House}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const BaggageIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 16,
  iconColor = 'backToBlack',
  onClick,
  disabled = false,
  dataQaid = 'baggage-icon',
}) => {
  return (
    <BaseIcon
      icon={Baggage}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const BandcampSocialIcon: React.FC<
  React.PropsWithChildren<IconProps>
> = ({
  iconSize = 20,
  iconColor = 'green600',
  onClick,
  disabled = false,
  dataQaid = 'bandcamp-social-icon',
}) => {
  return (
    <BaseIcon
      icon={socialIconsForAdminManualCSS.urlBandcamp}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const FacebookSocialIcon: React.FC<
  React.PropsWithChildren<IconProps>
> = ({
  iconSize = 20,
  iconColor = 'green600',
  onClick,
  disabled = false,
  dataQaid = 'facebook-social-icon',
}) => {
  return (
    <BaseIcon
      icon={socialIconsForAdminManualCSS.urlFacebook}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const InstagramSocialIcon: React.FC<
  React.PropsWithChildren<IconProps>
> = ({
  iconSize = 20,
  iconColor = 'green600',
  onClick,
  disabled = false,
  dataQaid = 'instagram-social-icon',
}) => {
  return (
    <BaseIcon
      icon={socialIconsForAdminManualCSS.urlInstagram}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const SoundcloudSocialIcon: React.FC<
  React.PropsWithChildren<IconProps>
> = ({
  iconSize = 20,
  iconColor = 'green600',
  onClick,
  disabled = false,
  dataQaid = 'soundcloud-social-icon',
}) => {
  return (
    <BaseIcon
      icon={socialIconsForAdminManualCSS.urlSoundcloud}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const SpotifySocialIcon: React.FC<
  React.PropsWithChildren<IconProps>
> = ({
  iconSize = 20,
  iconColor = 'green600',
  onClick,
  disabled = false,
  dataQaid = 'spotify-social-icon',
}) => {
  return (
    <BaseIcon
      icon={socialIconsForAdminManualCSS.urlSpotify}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const TiktokSocialIcon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconSize = 20,
  iconColor = 'green600',
  onClick,
  disabled = false,
  dataQaid = 'tiktok-social-icon',
}) => {
  return (
    <BaseIcon
      icon={socialIconsForAdminManualCSS.urlTiktok}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const TwitterSocialIcon: React.FC<
  React.PropsWithChildren<IconProps>
> = ({
  iconSize = 20,
  iconColor = 'green600',
  onClick,
  disabled = false,
  dataQaid = 'twitter-social-icon',
}) => {
  return (
    <BaseIcon
      icon={socialIconsForAdminManualCSS.urlTwitter}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const WebsiteSocialIcon: React.FC<
  React.PropsWithChildren<IconProps>
> = ({
  iconSize = 20,
  iconColor = 'green600',
  onClick,
  disabled = false,
  dataQaid = 'website-social-icon',
}) => {
  return (
    <BaseIcon
      icon={socialIconsForAdminManualCSS.urlWebsite}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const YoutubeSocialIcon: React.FC<
  React.PropsWithChildren<IconProps>
> = ({
  iconSize = 20,
  iconColor = 'green600',
  onClick,
  disabled = false,
  dataQaid = 'youtube-social-icon',
}) => {
  return (
    <BaseIcon
      icon={socialIconsForAdminManualCSS.urlYoutube}
      iconSize={iconSize}
      iconStrokeColor={iconColor}
      onClick={onClick}
      disabled={disabled}
      dataQaid={dataQaid}
    />
  );
};

export const socialIcons: {
  [key in SocialUrlKey]: React.FC<React.PropsWithChildren<IconProps>> | null;
} = {
  urlBandcamp: BandcampSocialIcon,
  urlFacebook: FacebookSocialIcon,
  urlInstagram: InstagramSocialIcon,
  urlSoundcloud: SoundcloudSocialIcon,
  urlSpotify: SpotifySocialIcon,
  urlSupport: null,
  urlTiktok: TiktokSocialIcon,
  urlTwitter: TwitterSocialIcon,
  urlVKontacte: null,
  urlWebsite: WebsiteSocialIcon,
  urlYoutube: YoutubeSocialIcon,
};
