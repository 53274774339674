import { useCallback, useContext, useEffect } from 'react';

import hotjarSetup from 'app/lib/hotjarSetup';
import { AuthContext } from 'app/shared/context/Auth';

export const useHotjar = () => {
  const { user } = useContext(AuthContext);

  const identifyUserToHotjar = useCallback(() => {
    const userId = user ? user.id : null;
    if (window && (window as any).hj) {
      (window as any).hj('identify', userId, {});
    }
  }, [user]);

  useEffect(() => {
    hotjarSetup(process.env.HOTJAR_ID || 637742); // If not present, use dev hotjar id by default
    identifyUserToHotjar();
  }, [identifyUserToHotjar]);

  const sendHotjarEvent = (value: any) => {
    if (window && (window as any).hj) {
      (window as any).hj('event', value);
    }
  };

  const sendHotjarStateChange = (value: string) => {
    if (window && (window as any).hj) {
      (window as any).hj('stateChange', value);
    }
  };

  return {
    identifyUserToHotjar,
    sendHotjarEvent,
    sendHotjarStateChange,
  };
};
