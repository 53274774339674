import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  AnchorOrigin,
  calculatePopperPosition,
  KeepInViewPort,
  Offset,
  PopoverDomEl,
  TransformOrigin,
} from 'app/shared/utils/calculatePopperPosition';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import { getPosition } from 'app/shared/utils/usePosition';
import useScrollPosition from 'app/shared/utils/useScrollPosition';
import useWindowSize from 'app/shared/utils/useWindowSize';
import { Box } from 'app/shared/components/atoms';

export interface PopperProps {
  anchorOrigin?: AnchorOrigin;
  transformOrigin?: TransformOrigin;
  offset?: Offset;
  keepInViewPort?: KeepInViewPort | boolean;
  flip?: boolean;
  reactToChange?: number | boolean;
  width?: string;
  zIndex?: number;
  isLastChild?: boolean;
  showDiamond?: boolean;
}

interface Props extends PopperProps {
  anchorEl: any;
  containerEl?: any;
  children: any;
}

interface CalculatedPosition {
  x: number;
  y: number;
  width: number;
  height: number;
  isFlipped: boolean;
}

export const Popper: React.FC<Props> = ({
  anchorEl,
  containerEl,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
  offset,
  keepInViewPort = false,
  flip = false,
  children,
  reactToChange,
  width,
  zIndex = 1005,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [calculatedPosition, setCalculatedPosition] =
    useState<CalculatedPosition>({
      x: -10000,
      y: -10000,
      width: 0,
      height: 0,
      isFlipped: false,
    });
  const [popoverElRect, setPopoverElRect] = useState<PopoverDomEl>({
    width: 0,
    height: 0,
  });
  const windowSize = useWindowSize();
  const { isDesktop } = useIsBreakpointSize();

  const updateCalculatedPosition = useCallback(
    (updatedAnchorEl: React.RefObject<HTMLDivElement>) => {
      if (updatedAnchorEl.current) {
        const pos = getPosition(updatedAnchorEl.current);

        const calculated = calculatePopperPosition(
          windowSize,
          pos,
          anchorOrigin,
          transformOrigin,
          popoverElRect,
          keepInViewPort,
          flip,
          offset
        );

        setCalculatedPosition(calculated);
      }
    },
    [
      windowSize,
      anchorOrigin,
      transformOrigin,
      popoverElRect,
      offset,
      keepInViewPort,
      flip,
    ]
  );

  useEffect(() => {
    if (popoverRef.current) {
      setPopoverElRect(getPosition(popoverRef.current));
    }
  }, [popoverRef, reactToChange]);

  useEffect(() => {
    updateCalculatedPosition(anchorEl);
  }, [
    anchorEl,
    popoverElRect,
    anchorOrigin,
    transformOrigin,
    updateCalculatedPosition,
    windowSize,
  ]);

  useScrollPosition(
    () => {
      if (popoverRef.current) {
        setPopoverElRect(getPosition(popoverRef.current));
      }
      updateCalculatedPosition(anchorEl);
    },
    [],
    containerEl,
    !containerEl
  );

  const { y, x, width: anchorElWidth } = calculatedPosition;
  const w = width === 'auto' ? `${anchorElWidth}px` : width;
  const justifyContent = isDesktop ? undefined : 'center';

  return (
    <Box
      position="fixed"
      top={`${y}px`}
      left={`${x}px`}
      zIndex={zIndex}
      w={w}
      ref={popoverRef}
      justifyContent={justifyContent}
    >
      {children}
    </Box>
  );
};
