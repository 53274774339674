import { useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';

import { GET_FEATURE_FLAGS } from 'app/shared/graphql/featureFlags/queries';

export interface FeatureFlagEnabled {
  featureFlagEnabled: boolean;
  name: string;
  experimentEligible: boolean;
}

interface GetFeatureFlagsProps {
  flags?: string[];
  flipperId?: string | null;
  shouldLoadFlags?: boolean;
}

interface GetFeatureFlagsData {
  featureFlags: FeatureFlagEnabled[];
}

export const GetFeatureFlags = ({
  flags,
  flipperId,
  shouldLoadFlags = true,
}: GetFeatureFlagsProps) => {
  const variables = flipperId ? { flags, flipperId } : { flags };

  const [loadFeatureFlags, response] = useLazyQuery<GetFeatureFlagsData>(
    GET_FEATURE_FLAGS,
    {
      variables,
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (flags && shouldLoadFlags && response.called !== true) {
      loadFeatureFlags();
    }
  }, [flags, shouldLoadFlags, loadFeatureFlags, response]);

  return response;
};
