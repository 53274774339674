import { MouseEvent, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAnalyticsContext } from 'app/shared/utils';
import { AuthContext } from 'app/shared/context/Auth';

function checkIsExternalURL(url?: string) {
  if (!url) {
    return false;
  }
  return url.match(/^(http|https):\/\/(.*)/g) !== null;
}

function getLinkRel(
  openInNewTab: boolean,
  isExternalURL: boolean,
  rel?: string
) {
  if (openInNewTab) {
    if (isExternalURL) {
      // This prevents Reverse Tabnabbing
      // https://hackernoon.com/prevent-reverse-tabnabbing-attacks-with-proper-noopener-noreferrer-and-nofollow-attribution-z14d3zbh
      return 'noopener noreferrer';
    }
    return 'noopener';
  }
  return rel;
}

export const useLinkClickBehavior = ({
  openInNewTab = false,
  href,
  target,
  rel,
  onClick,
}: React.ComponentPropsWithoutRef<'a'> & { openInNewTab?: boolean }) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { visitorType } = useContext(AuthContext);
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const isExternalURL = checkIsExternalURL(href);
  const newRel = getLinkRel(openInNewTab, isExternalURL, rel);
  const newTarget = openInNewTab ? '_blank' : target;

  function handleClick(event: MouseEvent<HTMLAnchorElement>) {
    const anchorNode = anchorRef.current;

    if (anchorNode) {
      trackAnalyticsEvent('Link Clicked', {
        location: anchorNode.baseURI,
        text: anchorNode.textContent,
        dataQaid: anchorNode.dataset.qaid,
        href,
        visitor_type: visitorType,
      });
    }

    if (onClick) {
      onClick(event);
    }

    // When navigating using the anchor tag's href, the user
    // goes to the next page before we can run the onClick callback and
    // track events. Preventing default behavior and navigating using react-router-dom
    // fixes this
    if (href && !isExternalURL && newTarget !== '_blank') {
      event.preventDefault ? event.preventDefault() : null;
      // Need some time to track events before redirecting user.
      // Otherwise event is not tracked or pathname is incorrect
      setTimeout(() => {
        navigate(href || '');
      }, 500);
      return false;
    }

    return;
  }

  return { anchorRef, handleClick, newRel, newTarget };
};
