import React from 'react';
import styled, { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import {
  withBackgroundCSS,
  WithBackgroundProps,
} from 'app/shared/components/styleUtils/withBackgroundCSS';
import {
  withBorderCSS,
  WithBorderProps,
} from 'app/shared/components/styleUtils/withBorderCSS';
import {
  withCursorCSS,
  WithCursorProps,
} from 'app/shared/components/styleUtils/withCursorCSS';
import {
  withDimensionsCSS,
  WithDimensionsProps,
} from 'app/shared/components/styleUtils/withDimensionsCSS';
import {
  withFlexCSS,
  WithFlexProps,
} from 'app/shared/components/styleUtils/withFlexCSS';
import {
  withPositionCSS,
  WithPositionProps,
} from 'app/shared/components/styleUtils/withPositionCSS';
import {
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils/withSpacingCSS';
import {
  withZIndexCSS,
  WithZIndexProps,
} from 'app/shared/components/styleUtils/withZIndexCSS';

export interface BoxProps
  extends React.ComponentPropsWithRef<'div'>,
    WithSpacingProps,
    WithFlexProps,
    WithBorderProps,
    WithPositionProps,
    WithCursorProps,
    WithZIndexProps,
    WithBackgroundProps,
    WithDimensionsProps {
  boxShadow?: string;
  display?: string;
  visibility?: string;
  transition?: string;
  animation?: any;
  listStyle?: string;
  as?: React.ElementType;
}

interface BoxCSSProps extends BoxProps {
  theme: CommonTheme;
}

export const Box = styled.div<BoxCSSProps>`
  ${({
    boxShadow,
    display,
    visibility,
    transition = 'background-color 400ms',
    animation,
    listStyle = 'none',
  }) => css`
    transition: ${transition};
    ${boxShadow && `box-shadow: ${boxShadow};`}
    ${display && `display: ${display};`}
    ${visibility && `visibility: ${visibility};`}
    ${
      animation &&
      css`
        animation: ${animation};
      `
    }
    list-style: ${listStyle};

  `}

  ${withBackgroundCSS};
  ${withFlexCSS};
  ${withSpacingCSS};
  ${withBorderCSS};
  ${withDimensionsCSS};
  ${withPositionCSS};
  ${withCursorCSS}
  ${withZIndexCSS};
`;
