import React from 'react';
import styled, { css } from 'styled-components';

import {
  withFlexCSS,
  WithFlexProps,
} from 'app/shared/components/styleUtils/withFlexCSS';
import {
  withFontCSS,
  WithFontProps,
} from 'app/shared/components/styleUtils/withFontCSS';
import { withLinkStyleCSS } from 'app/shared/components/styleUtils/withLinkStyleCSS';
import {
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils/withSpacingCSS';

interface DynamicHtmlContentProps
  extends React.ComponentPropsWithoutRef<'div'>,
    WithFlexProps,
    WithFontProps,
    WithSpacingProps {
  color?: string;
  linkVariant: 'primary' | 'secondary' | 'tertiary';
}

export const DynamicHtmlContent = styled.div<DynamicHtmlContentProps>`
  ${({ theme, color, linkVariant }) => css`
    margin: 0;
    padding: 0;
    color: ${color ? color : theme.colors.primary.black.base};

    ${withFlexCSS};
    ${withFontCSS};
    ${withSpacingCSS};

    div,
    p,
    span,
    b,
    i,
    em,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
      color: inherit;
    }

    div,
    p,
    span {
      ${withFontCSS};
    }

    b {
      ${withFontCSS({ theme, fontWeight: 'bold' })}
    }

    i,
    em {
      ${withFontCSS({ theme, fontStyle: 'italic' })}
    }

    h1 {
      ${withFontCSS({
        theme,
        fontSize: 'fontSize700',
        fontFamily: theme.fonts.title,
      })}
    }

    h2 {
      ${withFontCSS({
        theme,
        fontSize: 'fontSize600',
        fontFamily: theme.fonts.title,
      })}
    }

    h3 {
      ${withFontCSS({
        theme,
        fontSize: 'fontSize500',
        fontFamily: theme.fonts.title,
      })}
    }

    h4 {
      ${withFontCSS({
        theme,
        fontSize: 'fontSize400',
        fontFamily: theme.fonts.title,
      })}
    }

    h5,
    h6 {
      ${withFontCSS({
        theme,
        fontSize: 'fontSize300',
        fontFamily: theme.fonts.title,
      })}
    }

    a {
      ${withLinkStyleCSS({ theme, variant: linkVariant })};
    }
  `}
`;
