import { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';

export interface WithCursorProps {
  cursor?: string;
}

interface WithCursorCSSProps extends WithCursorProps {
  theme: CommonTheme;
}

export const withCursorCSS = ({ cursor }: WithCursorCSSProps) => css`
  ${cursor && `cursor: ${cursor};`}
`;
