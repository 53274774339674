import React from 'react';
import { useIntl } from 'react-intl';

import { useAnalyticsContext } from 'app/shared/utils';
import { useAuthFormContext } from 'app/shared/context';
import {
  ActionLink,
  NavigationLink as Link,
} from 'app/shared/components/atoms';
import { FormErrorMessage } from 'app/shared/components/molecules';

interface Props {
  onClickSignUp?: () => void;
  onClickSignIn?: () => void;
  genericIntlId: string;
  displayIcon?: boolean;
}

export const AuthApiError: React.FC<Props> = ({
  onClickSignIn,
  onClickSignUp,
  genericIntlId,
  displayIcon = true,
}) => {
  const { formatMessage } = useIntl();
  const { isCompleteSignUpFlow, apiError, thirdPartyAuthProvider } =
    useAuthFormContext();
  const { trackAnalyticsEvent } = useAnalyticsContext();

  if (!apiError) {
    return null;
  }

  const errorMessage = apiError.message;

  const ifTheProblemPersistsContactUs = () => {
    return (
      <>
        {formatMessage({ id: 'authentication.error.ifTheProblemPersists' })}{' '}
        <Link
          isErrorMessage={true}
          href="https://help.sofarsounds.com/hc/en-us/requests/new"
        >
          {formatMessage({ id: 'authentication.error.contactUs' })}
        </Link>
        .
      </>
    );
  };

  if (
    !!thirdPartyAuthProvider &&
    errorMessage.includes('Email was not shared')
  ) {
    return (
      <FormErrorMessage
        displayIcon={displayIcon}
        data-qaid="email-missing-error"
      >
        {formatMessage(
          { id: 'authentication.error.thirdPartyAuthEmailMissingError' },
          { provider: thirdPartyAuthProvider }
        )}{' '}
        {ifTheProblemPersistsContactUs()}
      </FormErrorMessage>
    );
  } else if (thirdPartyAuthProvider) {
    return (
      <FormErrorMessage displayIcon={displayIcon}>
        {formatMessage({
          id: 'authentication.error.genericThirdPartyAuthError',
        })}{' '}
        {ifTheProblemPersistsContactUs()}
      </FormErrorMessage>
    );
  }

  if (errorMessage === 'GraphQL error: User already exists' && onClickSignIn) {
    trackAnalyticsEvent('Sign Up Form Error User Already Exists', {
      is_complete_sign_up_flow: isCompleteSignUpFlow,
    });
    return (
      <FormErrorMessage displayIcon={displayIcon}>
        {formatMessage({ id: 'authentication.error.userAlreadyExists' })}
        <Link isErrorMessage={true} onClick={onClickSignIn}>
          {formatMessage({ id: 'authentication.error.signIn' })}
        </Link>
        .
      </FormErrorMessage>
    );
  }

  if (errorMessage === 'GraphQL error: No such user' && onClickSignUp) {
    return (
      <FormErrorMessage displayIcon={displayIcon}>
        {formatMessage({ id: 'authentication.error.noSuchUser' })}
        <ActionLink isErrorMessage={true} onClick={onClickSignUp}>
          {formatMessage({ id: 'authentication.error.signUp' })}
        </ActionLink>
        {formatMessage({ id: 'authentication.error.forAnAccount' })}
      </FormErrorMessage>
    );
  }

  if (errorMessage === 'GraphQL error: Password incorrect') {
    return (
      <FormErrorMessage displayIcon={displayIcon}>
        {formatMessage({ id: 'authentication.error.wrongPassword' })}
        <Link isErrorMessage={true} href="/auth/secret/new">
          {formatMessage({ id: 'authentication.error.resetPassword' })}
        </Link>
        .
      </FormErrorMessage>
    );
  }

  return (
    <FormErrorMessage displayIcon={displayIcon}>
      {formatMessage({ id: genericIntlId })}
      {ifTheProblemPersistsContactUs()}
    </FormErrorMessage>
  );
};

export default AuthApiError;
