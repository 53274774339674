import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import config from 'app/lib/config';

// GA4
// GA4 Events Doc: https://developers.google.com/analytics/devguides/collection/ga4/reference/events
export function initializeGA4() {
  try {
    ReactGA.initialize(process.env.GA_MEASUREMENT_ID || '');
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
    }
  }
}

function paramsViewItem(params: any) {
  return {
    currency: params?.currency,
    value: params?.price,
    items: [
      {
        item_id: params?.product_id,
        item_name: params?.category,
        item_category: params?.city_slug,
        item_category3: params?.campaign?.source,
        item_category4: params?.campaign?.medium,
        item_category5: params?.campaign?.name,
        price: params?.price,
        item_variant: params?.user_state,
      },
    ],
  };
}

function paramsAddPaymentInfo(params: any) {
  return {
    currency: params?.currency,
    value: params?.price,
    coupon: params?.promo_code,
    payment_type: params?.payment_type,
    items: [
      {
        item_id: params?.product_id,
        item_name: params?.category,
        item_category: params?.city_slug,
        item_category3: params?.campaign?.source,
        item_category4: params?.campaign?.medium,
        item_category5: params?.campaign?.name,
        price: params?.price,
        quantity: params?.num_items,
      },
    ],
  };
}

function paramsBeginCheckout(params: any) {
  return {
    currency: params?.currency,
    value: params?.value,
    coupon: params?.promo_code,
    payment_type: params?.payment_type,
    items: [
      {
        item_id: params?.content_ids[0],
        item_name: params?.category,
        item_category: params?.city_slug,
        item_category3: params?.campaign?.source,
        item_category4: params?.campaign?.medium,
        item_category5: params?.campaign?.name,
        item_list_name: params?.visitor_type,
        price: params?.value,
        quantity: params?.num_items,
      },
    ],
  };
}

function paramsViewItemList(params: any) {
  return {
    items: params?.visible_event_ids.map((event_id: string) => ({
      item_id: event_id,
      item_name: params?.sofar_operated_market_type,
      item_category: params?.city_slug,
      item_category3: params?.campaign?.source,
      item_category4: params?.campaign?.medium,
      item_category5: params?.campaign?.name,
      index: params?.current_page,
    })),
  };
}

function paramsAddToCart(params: any) {
  return {
    currency: params?.currency,
    value: params?.value,
    items: [
      {
        item_id: params?.content_ids[0],
        item_name: params?.category,
        item_category: params?.city_slug,
        item_category3: params?.campaign?.source,
        item_category4: params?.campaign?.medium,
        item_category5: params?.campaign?.name,
        price: params?.value,
      },
    ],
  };
}

function paramsSearch(params: any) {
  return {
    search_term: params?.search_term,
  };
}

function mapSegmentToGA4(eventName: string, params: any) {
  switch (eventName) {
    case 'Payment Info Entered':
      return ['add_payment_info', paramsAddPaymentInfo(params)];
    case 'Product Viewed':
      return ['view_item', paramsViewItem(params)];
    case 'Checkout Started':
      return ['begin_checkout', paramsBeginCheckout(params)];
    case 'Product List Viewed':
      return ['view_item_list', paramsViewItemList(params)];
    case 'Product Added':
      return ['add_to_cart', paramsAddToCart(params)];
    case 'City Selector Search Executed':
      return ['search', paramsSearch(params)];
    default:
      return [eventName, params];
  }
}

export function sendGA4Event(eventName: string, params?: any) {
  const [eventNameGA4, paramsGA4] = mapSegmentToGA4(eventName, params);
  ReactGA.event(eventNameGA4, paramsGA4);
}

// Regular GTM
const enableGtm = config.googleTagManager.enabled;

export function initializeGTM() {
  if (typeof window !== 'undefined') {
    if (enableGtm) {
      TagManager.initialize({
        gtmId: config.googleTagManager.key,
      });
    }
  }
}

export default {
  dataLayer: (dataLayer: any) => {
    if (!enableGtm) {
      // eslint-disable-next-line
      console.log(`[Google Tag Manager]: change data layer`, dataLayer);
      return;
    }

    if (typeof window !== 'undefined') {
      TagManager.dataLayer({ dataLayer });
    }
  },
};
