import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { handleAutomaticPageReload } from 'app/lib/automaticPageReload';
import { ReactComponent as PrimaryLogo } from 'icons/sofar-logos/primary.svg';

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 80px 50px 80px 50px;
  max-width: 400px;
`;

const LogoContainer = styled.div`
  margin-bottom: 50px;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const HeaderText = styled.div`
  font-family: bely-display, Georgia, serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 56px;
  white-space: nowrap;
  color: #1c1c1c;
  margin-bottom: 10px;
`;

const ErrorText = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 22.4px;
  color: #1c1c1c;
  text-align: center;
`;

const HomePageLink = styled.a`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 22.4px;
  color: #d1a03f;
  cursor: pointer;
`;

const LogoWrapper = styled.span`
  > svg {
    fill: #d1a03f;
    path {
      fill: #d1a03f;
    }
    overflow: visible;
  }
  height: fit-content;
  display: flex;
`;

const Logo: React.FC = () => {
  const height = 64;
  const width = height * 2.125;

  return (
    <LogoWrapper>
      {React.createElement(PrimaryLogo || 'span', { height, width })}
    </LogoWrapper>
  );
};

const MainRootError: React.FC = () => {
  const [reloadSequenceComplete, setReloadSequenceComplete] = useState(false);

  useEffect(() => {
    if (!reloadSequenceComplete) {
      setReloadSequenceComplete(handleAutomaticPageReload());
    }
  }, []);

  // Prevent momentary rendering of React tree while page is reloading
  if (!reloadSequenceComplete) {
    return null;
  }

  return (
    <MainContainer>
      <ContentContainer>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <ErrorContainer>
          <HeaderText>Oh no...</HeaderText>
          <ErrorText>
            Something&apos;s gone wrong. We&apos;re looking into it. Please try{' '}
            <HomePageLink href="/">going to the home page</HomePageLink> and
            then navigating back to this page - the problem may fix itself!
          </ErrorText>
        </ErrorContainer>
      </ContentContainer>
    </MainContainer>
  );
};

export default MainRootError;
