import {
  Fonts,
  FontSizes,
  FontWeights,
  LetterSpacings,
} from 'app/shared/theme/common/typings';

export const fonts: Fonts = {
  regular: 'futura-pt, sans-serif', // 200 and down, with some exceptions
  title: 'bely-display, Georgia, serif', // 300 and up, with some exceptions
};

// WEIGHT //

export const fontWeights: FontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

// LETTER SPACING //

export const letterSpacings: LetterSpacings = {
  default: '0px',
  uppercase: '2px',
  button: '2px',
};

// SIZING //

const fontSize25 = {
  mobile: '10px',
  desktop: '10px',
};
const fontSize50 = {
  mobile: '12px',
  desktop: '12px',
};
const fontSize75 = {
  mobile: '14px',
  desktop: '14px',
};
const fontSize100 = {
  mobile: '16px',
  desktop: '16px',
};
const fontSize200 = {
  mobile: '18px',
  desktop: '18px',
};
const fontSize300 = {
  mobile: '20px',
  desktop: '20px',
};
const fontSize400 = {
  mobile: '24px',
  desktop: '24px',
};
const fontSize500 = {
  mobile: '28px',
  desktop: '32px',
};
const fontSize600 = {
  mobile: '32px',
  desktop: '40px',
};
const fontSize700 = {
  mobile: '40px',
  desktop: '48px',
};

export const fontSizes: FontSizes = {
  root: fontSize100,
  rootLineHeight: '140%',
  rootLetterSpacing: letterSpacings.default,
  fontSize25,
  fontSize50,
  fontSize75,
  fontSize100,
  fontSize200,
  fontSize300,
  fontSize400,
  fontSize500,
  fontSize600,
  fontSize700,
  // icon sizing should NEVER be used for text - only for icons - hence the inline definitions instead of variable use :)
  icon: {
    xs: {
      mobile: '12px',
      desktop: '12px',
    },
    sm: {
      mobile: '16px',
      desktop: '16px',
    },
    md: {
      mobile: '20px',
      desktop: '20px',
    },
    lg: {
      mobile: '24px',
      desktop: '24px',
    },
    xl: {
      mobile: '32px',
      desktop: '40px',
    },
  },
};
