import styled, { css } from 'styled-components';

import {
  withDimensionsCSS,
  WithDimensionsProps,
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';
import { Divider, DividerProps } from 'app/shared/components/atoms';

export interface AlternativesDividerProps
  extends DividerProps,
    WithSpacingProps,
    WithDimensionsProps {
  backgroundColor?: string;
  content?: string;
  textColor?: string;
}

export const AlternativesDivider = styled(Divider)<AlternativesDividerProps>`
  ${({ theme, textColor, backgroundColor, content = 'Or' }) => css`
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    height: 1px;

    &:after {
      content: '${content}';
      padding: 0 ${theme.ruler[4]}px;
      background-color: ${
        backgroundColor ? backgroundColor : theme.colors.primary.white.lightest
      };
      position: sticky;
      color: ${textColor ? textColor : theme.colors.primary.black.lighter};
      width: fit-content;
      font-family: ${theme.fonts.title};
      font-size: ${theme.fontSizes.fontSize300.mobile};

      ${theme.media.smallDesktop`
        font-size: ${theme.fontSizes.fontSize300.desktop};
      `}
    }
  `}

  ${withDimensionsCSS};
  ${withSpacingCSS};
  line-height: 100%;
`;
