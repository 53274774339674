import { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';

export interface WithMaxPageWidthProps {
  withMaxPageWidth?: boolean;
}

interface WithMaxPageWidthCSSProps extends WithMaxPageWidthProps {
  theme: CommonTheme;
}

export const withMaxPageWidthCSS = ({
  theme,
  withMaxPageWidth,
}: WithMaxPageWidthCSSProps) => css`
  ${
    withMaxPageWidth &&
    css`
      margin: 0 auto;
      max-width: ${theme.dimensions.maxPageWidth}px;
    `
  }
`;
