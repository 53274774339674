import React, { useEffect, useRef, useState } from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import useKeyDown from 'app/shared/utils/useKeyDown';
import useOutsideClick from 'app/shared/utils/useOutsideClick';
import { WithInputStyleProps } from 'app/shared/components/styleUtils';
import {
  Icon,
  InputHelperText,
  InputStatusIcon,
  Spinner,
  TextInput,
} from 'app/shared/components/atoms';
import { Box, Typography } from 'app/shared/components/atoms';
import { ReactComponent as ChevronDown } from 'icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'icons/chevron-up.svg';
import { ReactComponent as Search } from 'icons/search.svg';

import { Options, SharedSelectProps } from './SelectOptions';

interface Props
  extends React.ComponentPropsWithoutRef<'select'>,
    WithInputStyleProps,
    SharedSelectProps {
  label: string | null;
  initialWidth?: string;
  isDisabled?: boolean;
  hasDataError?: boolean;
  hasValidationError?: boolean;
  handleOptionClick?: (value: string | number, option: any) => void;
  onClickResetInput?: () => void;
  onOpen?: () => void;
  id?: string;
  getOptionLabel?: (o: any) => string;
  isSearchable?: boolean;
  onSearch?: (input: string) => void;
  filterBy?: (option: any, query?: string) => boolean;
  value?: string | number;
  defaultValue?: any | null;
  helperText?: string | React.ReactNode | null;
  labelColor?: string;
  iconColor?: string;
  isRequired?: boolean;
}

export const Select: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  helperText,
  options,
  noOptionsMessage,
  isLoading = false,
  isDisabled = false,
  hasDataError = false,
  hasValidationError = false,
  isSuccessful = false,
  handleOptionClick,
  onClickResetInput,
  name,
  id,
  getOptionLabel,
  optionValueKey,
  isSearchable,
  onSearch,
  filterBy,
  value,
  backgroundColor,
  labelColor,
  color,
  invertColors,
  groupBy,
  onOpen,
  defaultValue,
  initialWidth,
  mostVisitedOptions,
  recommendedOptions,
  renderOption,
  noOptionsOption,
  iconColor,
  placeholderColor,
  placeholder,
  isRequired,
  getRecommendedOptionsTitle,
  getMostVisitedOptionsTitle,
  'data-qaid': qaId,
}) => {
  const { ruler, colors, borderRadius, fontSizes } = useCurrentTheme();
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const [selectValue, setSelectValue] = useState<string | number>(
    defaultValue || value || ''
  );

  const [filteredOptions, setFilteredOptions] = useState<any>(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useOutsideClick(ref, () => setIsOpen(false));
  useKeyDown('Escape', () => setIsOpen(false));

  const onOptionClick = (optionValue: string | number, option: any) => {
    const label = getOptionLabel ? getOptionLabel(option) : option.label;
    setSelectValue(label);
    setIsOpen(false);
    handleOptionClick && handleOptionClick(optionValue, option);
  };

  useEffect(() => {
    if (value || value == '') {
      setSelectValue(value);
    }
  }, [value]);

  function resetSearch() {
    setFilteredOptions(options);
    setSelectValue('');
    onSearch && onSearch('');
    setIsOpen(true);
  }

  const handleSearch = (searchString: string) => {
    if (!options) {
      return;
    }

    setSelectValue(searchString);

    if (searchString.trim() == '') {
      resetSearch();
    } else {
      const newFilteredOptions = options.filter((o: any) => {
        if (filterBy) {
          return filterBy(o, searchString);
        }
        const label = getOptionLabel ? getOptionLabel(o) : o.label;
        return label.toLowerCase().includes(searchString.toLowerCase());
      });
      trackAnalyticsEvent('Select Option Selected', {
        newFilteredOptions,
        searchString,
        id,
      });
      setFilteredOptions(newFilteredOptions);
      onSearch && onSearch(searchString);
      setIsOpen(true);
    }
  };

  const hasError = hasValidationError || hasDataError;
  const getInputStatusIconType = () => {
    if (hasError) {
      return 'ERROR';
    }
    if (!isDisabled && (!!value || !!selectValue)) {
      return 'RESET';
    }

    return null;
  };
  const inputStatusIconType = getInputStatusIconType();

  function handleClick() {
    if (isOpen) {
      !isSearchable && setIsOpen(false);
      return;
    }

    setIsOpen(true);
    onOpen && onOpen();
  }

  return (
    <Box
      position="relative"
      w="100%"
      h="fit-content"
      minWidth="fit-content"
      flexDirection="column"
      alignItems="stretch"
      backgroundColor={backgroundColor}
      data-qaid="select-container"
      borderRadius={borderRadius.input}
    >
      {label && (
        <Typography
          lineHeight="100%"
          fontSize="fontSize50"
          fontWeight="semibold"
          textTransform="uppercase"
          mb={2}
          color={hasError ? colors.messaging.error.dark : labelColor}
          data-qaid={`${qaId}-label`}
        >
          {label}
          {isRequired ? '*' : ''}
        </Typography>
      )}
      <Box flexDirection="row" alignItems="center" onClick={handleClick}>
        <TextInput
          data-qaid={`${qaId}-input`}
          pl={isSearchable && !isDisabled ? [10, 10, 11, 11] : undefined}
          ref={ref}
          disabled={isDisabled}
          hasError={hasError}
          backgroundColor={backgroundColor}
          placeholderColor={placeholderColor}
          color={color}
          invertColors={invertColors}
          name={name}
          id={id}
          onChange={(e: any) => handleSearch(e.target.value)}
          autoComplete="off"
          value={selectValue}
          width={initialWidth}
          aria-required={isRequired}
          placeholder={placeholder}
          readOnly={!isSearchable}
          borderRadiusBottomLeft={!isSearchable && isOpen ? '0px' : undefined}
          borderRadiusBottomRight={!isSearchable && isOpen ? '0px' : undefined}
          b={isOpen ? colors.accent.primaryAccent.base : undefined}
          cursor={isSearchable ? undefined : 'pointer'}
        />
        {isSearchable && !isDisabled && (
          <Box
            position="absolute"
            left={`${ruler[4]}px`}
            alignItems="center"
            onClick={() => undefined}
          >
            <Icon
              icon={Search}
              size="sm"
              strokeColor={
                isDisabled
                  ? colors.primary.black.lightest
                  : iconColor || colors.primary.black.base
              }
              fillColor="transparent"
            />
          </Box>
        )}
        <Options
          data-qaid={qaId}
          groupBy={groupBy}
          isOpen={isOpen}
          options={filteredOptions}
          noOptionsMessage={noOptionsMessage}
          recommendedOptions={recommendedOptions}
          mostVisitedOptions={mostVisitedOptions}
          isLoading={!!isLoading}
          hasError={!!hasDataError}
          onOptionClick={onOptionClick}
          getOptionLabel={getOptionLabel}
          optionValueKey={optionValueKey}
          onBlur={() => setIsOpen(false)}
          isSearchable={isSearchable}
          userIsSearching={isSearchable && !!selectValue}
          renderOption={renderOption}
          noOptionsOption={noOptionsOption}
          invertColors={invertColors}
          selectRef={ref}
          getRecommendedOptionsTitle={getRecommendedOptionsTitle}
          getMostVisitedOptionsTitle={getMostVisitedOptionsTitle}
        />
        {isLoading && (
          <Box position="absolute" right={`${ruler[4]}px`}>
            <Spinner
              size={fontSizes.fontSize300.mobile}
              invert
              data-qaid={`${qaId}-spinner`}
            />
          </Box>
        )}
        {!isLoading && isSearchable && (
          <InputStatusIcon
            iconType={inputStatusIconType}
            onClickResetInput={() => {
              resetSearch();
              onClickResetInput && onClickResetInput();
            }}
            position="absolute"
            right={`${ruler[4]}px`}
            invertColors={invertColors}
          />
        )}
        {!isSearchable && !isDisabled && (
          <Icon
            icon={isOpen ? ChevronUp : ChevronDown}
            size="sm"
            strokeColor={
              invertColors
                ? colors.primary.white.base
                : colors.primary.black.base
            }
            fillColor="transparent"
            position="absolute"
            right={`${ruler[4]}px`}
            pointerEvents="none"
          />
        )}
      </Box>
      {helperText && (
        <InputHelperText
          isDisabled={isDisabled}
          hasError={hasError}
          isSuccessful={isSuccessful}
          invertColors={invertColors}
          data-qaid={`${qaId}-helper-text`}
        >
          {helperText}
        </InputHelperText>
      )}
    </Box>
  );
};
