import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { Icon } from 'app/shared/components/atoms';
import { ReactComponent as Check } from 'icons/check.svg';

import { Option, SharedOptionsProps } from '.';

export interface SimpleOptionsProps extends SharedOptionsProps {
  onOptionClick: (value: any, option: any) => void;
  selectedOption?: string | number;
}

export const SimpleOptions: React.FC<SimpleOptionsProps> = ({
  onOptionClick,
  options,
  getOptionLabel,
  optionValueKey,
  invertColors,
  selectedOption,
}) => {
  const { colors } = useCurrentTheme();

  const optionsList = options.map((option: any, index: number) => {
    const value = optionValueKey ? option[optionValueKey] : option.id;
    const label = getOptionLabel ? getOptionLabel(option) : option.label;
    const isSelected = selectedOption === label;

    return (
      <Option
        data-qaid={label}
        key={index}
        value={value}
        onClick={() => onOptionClick(value, option)}
        invertColors={invertColors}
        isSelected={isSelected}
        isLastChild={index === options.length - 1}
        gap={2}
      >
        {isSelected && (
          <Icon
            icon={Check}
            size="sm"
            strokeColor={
              invertColors
                ? colors.primary.white.base
                : colors.primary.black.base
            }
          />
        )}
        {label}
      </Option>
    );
  });

  return <>{optionsList}</>;
};

export default SimpleOptions;
