import React, { useEffect } from 'react';

interface Props {
  to: string;
}

const ExternalRedirect: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    window.location.replace(props.to);
  });
  return null;
};

export default ExternalRedirect;
