import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useCurrentTheme } from 'app/shared/theme';
import { useButtonClickBehavior } from 'app/shared/utils/useButtonClickBehavior';
import {
  withButtonStyleCSS,
  WithButtonStyleProps,
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';
import { Icon } from 'app/shared/components/atoms';
import { Box } from 'app/shared/components/atoms';
import { ReactComponent as Loading } from 'icons/loading.svg';

export interface ActionButtonProps
  extends React.ComponentPropsWithoutRef<'button'>,
    WithSpacingProps,
    React.AriaAttributes,
    WithButtonStyleProps {
  trailingIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  leadingIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  'data-qaid'?: string;
  flex?: React.CSSProperties['flex'];
}

const StyledButton = styled.button<ActionButtonProps>`
  ${withButtonStyleCSS};
  ${withSpacingCSS};
  flex-direction: column;

  ${({ flex }) => css`
    ${flex && `flex: ${flex}`};
  `}
`;

export const ActionButton: React.FC<ActionButtonProps> = ({
  children,
  isLoading: isLoadingProp = false,
  disabled,
  trailingIcon,
  leadingIcon,
  onClick,
  variant = 'primary',
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(isLoadingProp);
  const { buttonRef, handleClick } = useButtonClickBehavior({
    onClick,
    isLoading,
    setIsLoading,
  });
  const { colors } = useCurrentTheme();
  const loadingIconColor = colors.button[variant].text;

  useEffect(() => {
    setIsLoading(isLoadingProp);
  }, [isLoadingProp]);

  return (
    <StyledButton
      ref={buttonRef}
      onClick={handleClick}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      aria-disabled={disabled || isLoading}
      variant={variant}
      {...props}
    >
      <Box
        as="span"
        visibility={isLoading ? 'hidden' : 'visible'}
        flexDirection="row"
        justifyContent="center"
        gap={2}
        alignItems="center"
      >
        {leadingIcon && <Icon size="sm" icon={leadingIcon} />}
        {children}
        {trailingIcon && <Icon size="md" icon={trailingIcon} />}
      </Box>
      {isLoading && (
        <Icon
          size="md"
          icon={Loading}
          position="absolute"
          transform="scaleX(-1)"
          spin={true}
          fillColor="none"
          strokeColor={loadingIconColor}
        />
      )}
    </StyledButton>
  );
};
