import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export function initializeDatadog() {
  // Initialize Datadog
  // Ensure we've been given something for the secrets and values we need to
  // post anything of value.
  if (
    process.env.DD_ENV &&
    process.env.DD_APPLICATION_ID &&
    process.env.DD_CLIENT_TOKEN
  ) {
    // These options are common to all DD modules
    const commonDdOptions = {
      applicationId: String(process.env.DD_APPLICATION_ID),
      clientToken: String(process.env.DD_CLIENT_TOKEN),
      site: String(process.env.DD_SITE || 'datadoghq.eu'),
      service: 'sofar-client',
      env: String(process.env.DD_ENV),
    };

    datadogRum.init({
      ...commonDdOptions,
      sessionSampleRate: Number(process.env.DD_SESSION_SAMPLE_RATE || 20),
      sessionReplaySampleRate: Number(
        process.env.DD_SESSION_REPLAY_SAMPLE_RATE || 1
      ),
      trackInteractions: process.env.DD_TRACK_INTERACTIONS === 'true',
      trackResources: process.env.DD_TRACK_RESOURCES === 'true',
      trackLongTasks: true,

      defaultPrivacyLevel: 'mask-user-input',
      silentMultipleInit: true,
    });

    if (process.env.DD_RECORD_SESSIONS === 'true') {
      datadogRum.startSessionReplayRecording();
    }

    if (process.env.DD_RECORD_LOGS === 'true') {
      datadogLogs.init({
        ...commonDdOptions,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        sessionSampleRate: 100,
      });
    }
  }
}
