import React from 'react';
import styled from 'styled-components';

import {
  withDimensionsCSS,
  WithDimensionsProps,
  withPositionCSS,
  WithPositionProps,
} from 'app/shared/components/styleUtils';

const VisaSvg = styled.svg<WithPositionProps & WithDimensionsProps>`
  ${withPositionCSS};
  ${withDimensionsCSS};
`;

const visaBlue = '#1434CB';

interface Props extends WithPositionProps, WithDimensionsProps {
  'data-qaid'?: string;
  fill?: string;
}

export const VisaLogo: React.FC<Props> = ({
  'data-qaid': qaId = 'visa-logo',
  fill = visaBlue,
  h = '10px',
  ...props
}) => {
  return (
    <VisaSvg
      data-qaid={qaId}
      viewBox="0 0 31 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      h={h}
      w={`calc(${h} * 31 / 10)`}
      {...props}
    >
      <path
        d="M11.756.177 7.702 9.849H5.058L3.063 2.13c-.121-.475-.227-.65-.595-.85C1.867.954.874.648 0 .458L.06.177h4.257c.542 0 1.03.361 1.153.986L6.524 6.76 9.128.177h2.628ZM22.12 6.69c.01-2.553-3.53-2.693-3.505-3.834.007-.347.338-.716 1.061-.81.358-.047 1.346-.083 2.467.433l.44-2.051A6.723 6.723 0 0 0 20.24 0c-2.473 0-4.214 1.315-4.228 3.197-.016 1.393 1.242 2.17 2.19 2.632.975.474 1.302.779 1.299 1.202-.007.65-.778.936-1.499.947-1.257.02-1.987-.34-2.569-.61l-.453 2.118c.584.268 1.664.502 2.782.514 2.629 0 4.348-1.298 4.356-3.31Zm6.53 3.158h2.315L28.944.177h-2.136c-.48 0-.885.28-1.065.71L21.99 9.848h2.627l.522-1.445h3.21l.302 1.445Zm-2.791-3.427 1.317-3.632.758 3.632h-2.075ZM15.331.177l-2.069 9.672H10.76L12.83.177h2.501Z"
        fill={fill}
      />
    </VisaSvg>
  );
};

export default VisaLogo;
