import styled, { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import {
  withBackgroundCSS,
  WithBackgroundProps,
  withBorderCSS,
  WithBorderProps,
  withDimensionsCSS,
  WithDimensionsProps,
  withFlexCSS,
  WithFlexProps,
  withPositionCSS,
  WithPositionProps,
  withSpacingCSS,
  WithSpacingProps,
  withZIndexCSS,
  WithZIndexProps,
} from 'app/shared/components/styleUtils';

export interface GridItemProps
  extends WithSpacingProps,
    WithBorderProps,
    WithFlexProps,
    WithZIndexProps,
    WithPositionProps,
    WithBackgroundProps,
    WithDimensionsProps {
  mobile?: number;
  tablet?: number;
  smallDesktop?: number;
  largeDesktop?: number;
  mobileStart?: number;
  tabletStart?: number;
  smallDesktopStart?: number;
  largeDesktopStart?: number;
  rowSpan?: number;
  row?: number;
}

export const GridItem = styled.div<GridItemProps>`
  ${({
    theme,
    mobile = 4,
    tablet = 'auto',
    smallDesktop = 'auto',
    largeDesktop = 'auto',
    mobileStart = 'auto',
    tabletStart = 'auto',
    smallDesktopStart = 'auto',
    largeDesktopStart = 'auto',
    rowSpan,
    row,
  }) => css`
    grid-column-end: span ${mobile};
    grid-column-start: ${mobileStart};

    ${
      rowSpan &&
      !row &&
      css`
        grid-row: span ${rowSpan};
      `
    }

    ${
      row &&
      !rowSpan &&
      css`
        grid-row: ${row};
      `
    }

    ${theme.media.tablet`
      grid-column-start: ${tabletStart};
      grid-column-end: span ${tablet};
    `}

    ${theme.media.smallDesktop`
      grid-column-start: ${smallDesktopStart};
      grid-column-end: span ${smallDesktop};
    `}

    ${theme.media.largeDesktop`
      grid-column-start: ${largeDesktopStart};
      grid-column-end: span ${largeDesktop};
    `}
  `}
  ${withSpacingCSS};
  ${withFlexCSS};
  ${withBorderCSS};
  ${withPositionCSS};
  ${withZIndexCSS};
  ${withDimensionsCSS};
  ${withBackgroundCSS};
`;

// Grid
export interface GridProps extends WithSpacingProps, WithDimensionsProps {
  theme?: CommonTheme;
  numberOfColumns?: number;
  gap?: number;
  rowGap?: number;
  mobileGap?: number;
  tabletGap?: number;
  smallDesktopGap?: number;
  largeDesktopGap?: number;
  mobileNumberOfColumns?: number;
  tabletNumberOfColumns?: number;
  smallDesktopNumberOfColumns?: number;
  largeDesktopNumberOfColumns?: number;
  alignContent?: string;
  alignItems?: string;
  justifyContent?: string;
  justifyItems?: string;
}

export const Grid = styled.div<GridProps>`
  ${({
    theme,
    numberOfColumns = 12,
    gap = 2,
    rowGap,
    mobileGap,
    tabletGap,
    smallDesktopGap,
    largeDesktopGap,
    mobileNumberOfColumns,
    tabletNumberOfColumns,
    smallDesktopNumberOfColumns,
    largeDesktopNumberOfColumns,
    alignContent,
    alignItems,
    justifyContent,
    justifyItems,
  }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${numberOfColumns}, 1fr);
    grid-gap: ${theme.ruler[gap]}px;
    ${alignContent && `align-content: ${alignContent};`}
    ${alignItems && `align-items: ${alignItems};`}
    ${justifyContent && `justify-content: ${justifyContent};`}
    ${justifyItems && `justify-items: ${justifyItems};`}

    ${
      rowGap !== undefined &&
      `
      grid-row-gap: ${theme.ruler[rowGap]}px;
    `
    }

    ${theme.media.mobile`
      ${
        mobileGap &&
        `
        grid-column-gap: ${theme.ruler[mobileGap]}px;
      `
      }

      ${
        mobileNumberOfColumns &&
        `
        grid-template-columns: repeat(${mobileNumberOfColumns}, 1fr);
      `
      }
    `}


    ${theme.media.tablet`
      ${
        tabletGap &&
        `
        grid-column-gap: ${theme.ruler[tabletGap]}px;
      `
      }

      ${
        tabletNumberOfColumns &&
        `
        grid-template-columns: repeat(${tabletNumberOfColumns}, 1fr);
      `
      }
    `}


    ${theme.media.smallDesktop`
      ${
        smallDesktopGap &&
        `
        grid-column-gap: ${theme.ruler[smallDesktopGap]}px;
      `
      }

      ${
        smallDesktopNumberOfColumns &&
        `
        grid-template-columns: repeat(${smallDesktopNumberOfColumns}, 1fr);
      `
      }
    `}


    ${theme.media.largeDesktop`
      ${
        largeDesktopGap &&
        `
        grid-column-gap: ${theme.ruler[largeDesktopGap]}px;
      `
      }

      ${
        largeDesktopNumberOfColumns &&
        `
        grid-template-columns: repeat(${largeDesktopNumberOfColumns}, 1fr);
      `
      }
    `}
  `}

  ${withSpacingCSS};
  ${withDimensionsCSS};
`;
