import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import queryString, { ParsedQuery } from 'query-string';

import config from 'app/lib/config';

import 'isomorphic-fetch';

let urlToken;

if (typeof window !== 'undefined') {
  const query: ParsedQuery = queryString.parse(window.location.search);
  urlToken = query.token;
}

const httpLink = createHttpLink({
  // Append the operation name onto the GQL URL for understandability in logging when the POST
  // body is not present or not easily matched to the POST.
  uri: (operation) =>
    `${config.graphqlEndpoint}?on=${encodeURIComponent(
      operation.operationName
    )}`,
  credentials: 'include',
  headers: {
    token: urlToken,
  },
});

const cache = process.browser
  ? new InMemoryCache({
      // dataIdFromObject and related logic is to handle ArtistEvents component on ArtistDashboard,
      // which renders both a list of events and a list of featured sets, which might
      // occasionally have records with the same id (since they're separate db tables), which breaks
      // the page by suppressing one or the other of the records with the duplicate id, because
      // Apollo normally caches based on id - here, we are telling Apollo to cache ArtistEvent
      // records by both eventType and id, and cache everything else by id (normal behavior)
      dataIdFromObject(responseObject) {
        switch (responseObject.__typename) {
          case 'ArtistEvent':
            // @ts-ignore
            return `ArtistEvent:${responseObject.eventType}:${responseObject.id}`;
          default:
            return defaultDataIdFromObject(responseObject);
        }
      },
    }).restore((window as any).__APOLLO_STATE__)
  : new InMemoryCache();

export default new ApolloClient({
  link: httpLink,
  connectToDevTools: process.browser,
  ssrMode: process.env.NODE_ENV === 'development' && !process.browser,
  resolvers: {},
  cache,
});
