import { css } from 'styled-components';

export interface WithChildSVGProps {
  svgFillColor: string;
  svgStrokeColor: string;
}

export const withChildSVGStyleCSS = ({
  svgFillColor,
  svgStrokeColor,
}: WithChildSVGProps) => css`
  svg {
    transition: all 400ms;
    stroke: ${svgStrokeColor};
    fill: ${svgFillColor};
    path,
    rect,
    circle {
      transition: all 400ms;
      stroke: ${svgStrokeColor};
      fill: ${svgFillColor};
    }
  }
`;
