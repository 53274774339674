import gql from 'graphql-tag';

export const GET_CARD_REDEMPTIONS_STATUS = gql`
  query GetCardRedemptionsStatus(
    $calendarYear: Int!
    $cardId: String!
    $eligibilityLevel: String!
    $eventId: Int
    $cardType: String!
  ) {
    cardRedemptionsStatus(
      calendarYear: $calendarYear
      cardId: $cardId
      eligibilityLevel: $eligibilityLevel
      eventId: $eventId
      cardType: $cardType
    ) {
      visaFreeTicketRedeemedForEvent
      visaFreeTicketsRemaining
      metadata {
        totalRecords
      }
    }
  }
`;
