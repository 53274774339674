import { useCallback, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

import { useAnalyticsContext } from 'app/shared/utils';
import { AuthContext } from 'app/shared/context/Auth';

const useOptinMonster = (onOptinMonsterSuccess: (event: any) => void) => {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { loggedIn } = useContext(AuthContext);
  const { anonymousId } = useAnalyticsContext();

  const onOptinMonsterStartShow = useCallback(
    async (event: any) => {
      const cookies = Cookies.get();
      const utmAttributes = [
        'current_utm_source',
        'current_utm_medium',
        'current_utm_campaign',
        'current_utm_term',
        'current_utm_content',
        'current_utm_id',
        'current_referrer',
        'current_attribution_timestamp',
      ];

      event.detail.Campaign.Dtr.setCustomVariable('anonymousId', anonymousId);

      utmAttributes.forEach((utm_attr: string) => {
        event.detail.Campaign.Dtr.setCustomVariable(
          utm_attr,
          cookies[utm_attr]
        );
      });
      trackAnalyticsEvent('OIM Campaign Displayed', {
        oim_campaign_id: event.detail.Campaign.id,
      });
    },
    [trackAnalyticsEvent, anonymousId]
  );

  const onDisplayRulesFinish = useCallback(
    (event: any) => {
      const { DisplayRules } = event.detail;
      if (loggedIn) {
        DisplayRules.show = false;
      }
    },
    [loggedIn]
  );

  useEffect(() => {
    // If user is logged in, do not display the campaign
    document.addEventListener('om.DisplayRules.afterRun', onDisplayRulesFinish);
    // On campaign show start, send anonymous id and attribution data to OIM
    document.addEventListener('om.Campaign.startShow', onOptinMonsterStartShow);
    // On campaign success, display Sign Up form modal
    document.addEventListener('om.Optin.success', onOptinMonsterSuccess);

    return () => {
      document.removeEventListener(
        'om.Campaign.startShow',
        onOptinMonsterStartShow
      );
      document.removeEventListener('om.Optin.success', onOptinMonsterSuccess);
      document.removeEventListener(
        'om.DisplayRules.afterRun',
        onDisplayRulesFinish
      );
    };
  }, [onDisplayRulesFinish, onOptinMonsterStartShow, onOptinMonsterSuccess]);
};

export default useOptinMonster;
