import gql from 'graphql-tag';

export const GET_CITIES = gql`
  query GetCities(
    $citySearch: String
    $topGlobalCity: Boolean
    $topCity: Boolean
    $country: String
    $businessOwner: String
    $city: String
    $cityIds: String
    $isFeaturedSetEnabled: Boolean
    $isAcceptingArtistRequests: Boolean
    $cityOperatingModel: String
    $activeStatus: String
    $orderBy: String
    $orderDirection: String
    $page: Int
    $perPage: Int
    $skipPagination: Boolean
  ) {
    cities(
      citySearch: $citySearch
      topGlobalCity: $topGlobalCity
      topCity: $topCity
      country: $country
      businessOwner: $businessOwner
      city: $city
      cityIds: $cityIds
      isFeaturedSetEnabled: $isFeaturedSetEnabled
      isAcceptingArtistRequests: $isAcceptingArtistRequests
      cityOperatingModel: $cityOperatingModel
      activeStatus: $activeStatus
      orderBy: $orderBy
      orderDirection: $orderDirection
      page: $page
      perPage: $perPage
      skipPagination: $skipPagination
    ) {
      cities {
        id
        title
        cachedSlug
        latitude
        longitude
        cityStatus
        createdAt
        aliases
        cityOperatingModel
        cityOperatingModel
        isAcceptingArtistRequests
        featuredSetMinimumPrice
        country {
          id
          title
        }
        businessOwner {
          id
          title
          cachedSlug
        }
        neighborhoods {
          id
          title
          cachedSlug
        }
      }
      metadata {
        totalRecords
        currentPage
      }
    }
  }
`;

export const GET_CITIES_FOR_CITY_SELECTOR = gql`
  query GetCitiesForCitySelector(
    $citySearch: String
    $topGlobalCity: Boolean
    $cityOperatingModel: String
    $activeStatus: String
    $orderBy: String
    $orderDirection: String
    $isLiteQuery: Boolean
  ) {
    cities(
      citySearch: $citySearch
      topGlobalCity: $topGlobalCity
      cityOperatingModel: $cityOperatingModel
      activeStatus: $activeStatus
      orderBy: $orderBy
      orderDirection: $orderDirection
      isLiteQuery: $isLiteQuery
    ) {
      cities {
        id
        title
        cachedSlug
        aliases
        cityOperatingModelDescription
        timezone
        country {
          id
          title
        }
      }
    }
  }
`;

export const GET_CITIES_FOR_GROUP_BY_BUSINESS_OWNER = gql`
  query GetCitiesForGroupByBusinessOwner(
    $businessOwner: String
    $activeStatus: String
    $isLiteQuery: Boolean
    $cityOperatingModel: String
  ) {
    cities(
      businessOwner: $businessOwner
      activeStatus: $activeStatus
      isLiteQuery: $isLiteQuery
      cityOperatingModel: $cityOperatingModel
    ) {
      cities {
        id
        title
        cachedSlug
        aliases
        latitude
        longitude
        country {
          id
          title
          currencyCode
        }
        businessOwner {
          id
          title
          cachedSlug
        }
      }
    }
  }
`;

export const GET_CITIES_FOR_WEBSITE_FOOTER = gql`
  query GetCitiesForWebsiteFooter($topCity: Boolean, $isLiteQuery: Boolean) {
    cities(topCity: $topCity, isLiteQuery: $isLiteQuery) {
      cities {
        id
        title
        cachedSlug
      }
    }
  }
`;

export const GET_CITIES_LITE = gql`
  query GetCitiesLite(
    $citySearch: String
    $topGlobalCity: Boolean
    $topCity: Boolean
    $country: String
    $activeStatus: String
    $isLiteQuery: Boolean
  ) {
    cities(
      citySearch: $citySearch
      topGlobalCity: $topGlobalCity
      topCity: $topCity
      country: $country
      activeStatus: $activeStatus
      isLiteQuery: $isLiteQuery
    ) {
      cities {
        id
        title
        cachedSlug
        aliases
        latitude
        longitude
        currencyCode
        country {
          id
          countryCode
          title
          currencyCode
        }
      }
    }
  }
`;

export const GET_CITY = gql`
  query GetCity($id: Int, $cachedSlug: String) {
    city(id: $id, cachedSlug: $cachedSlug) {
      id
      timezone
      title
      cachedSlug
      cityOperatingModel
      description
      whatToExpect
      images {
        header {
          ratio1x1 {
            id
            ratio
            filename
            fileLocation
          }
        }
      }
      country {
        id
        title
        cachedSlug
        countryCode
      }
      eventCollections {
        id
        position
        status
        followingsEnabled
        upcomingEventsInCityCount
        title
        description
        cachedSlug
        images {
          header {
            ratio1x1 {
              id
              ratio
              filename
              fileLocation
            }
            ratio16x9 {
              id
              ratio
              filename
              fileLocation
            }
          }
        }
        events {
          id
          startsAt
          localStartsAt
          guestsArriveAt
          neighborhood {
            id
            title
          }
          isGloballyPromoted
          isPublished
          isPurchasable
          cancelled
          isAppliable
          isSoldOut
          attendeeFlow
          isVenueConfirmed
          venue {
            id
            neighborhood {
              id
              title
            }
            venueCategories {
              id
              name
              isVisibleToFans
            }
          }
          theme {
            id
            title
          }
          city {
            id
            cachedSlug
            title
            timezone
            country {
              id
              countryCode
            }
          }
        }
      }
    }
  }
`;

export const GET_CITY_FOR_CLP = gql`
  query GetCityForClp($id: Int, $cachedSlug: String) {
    city(id: $id, cachedSlug: $cachedSlug) {
      id
      title
      cachedSlug
      cityOperatingModelDescription
      aliases
      imageUrl
      latitude
      longitude
      country {
        id
        title
        cachedSlug
        countryCode
        currencyCode
        currencySymbol
      }
    }
  }
`;

export const GET_CLOSEST_CITIES = gql`
  query GetClosestCities(
    $count: Int
    $distanceLimit: Int
    $prioritizeOAndOCities: Boolean
    $hasUpcomingEvents: Boolean
    $skipCachedCities: Boolean
  ) {
    closestCities(
      count: $count
      distanceLimit: $distanceLimit
      prioritizeOAndOCities: $prioritizeOAndOCities
      hasUpcomingEvents: $hasUpcomingEvents
      skipCachedCities: $skipCachedCities
    ) {
      cities {
        id
        title
        cachedSlug
        aliases
        description
        cityOperatingModel
        country {
          id
          title
          countryCode
        }
      }
    }
  }
`;
