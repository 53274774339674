import React, { useCallback, useContext } from 'react';
import { useMemo, useState } from 'react';
import { ApolloError } from 'apollo-client';
import { useMutation } from 'react-apollo';

import { useAnalyticsContext } from 'app/shared/utils';
import { useInferredUserCountryCode } from 'app/shared/utils/useInferredUserCountryCode';
import { UpdateUserProfileData, User } from 'app/shared/typings';
import { UPDATE_USER_PROFILE } from 'app/shared/graphql/users/mutations';
import { AuthContext } from 'app/shared/context/Auth';

interface SmsMarketingSignUpFormValues {
  onSmsSignUpSubmit: (props: OnSignUpSubmitProps) => void;
  initialFormValues: any;
  shouldDisplaySmsOptIn: boolean;
}

type SmsMarketingSignUpForm = (args: {
  formContext?: string;
  hide?: boolean;
  onSuccess?: () => void;
}) => SmsMarketingSignUpFormValues;

interface MultiStepSignUpContextValue {
  newUser?: User;
  setNewUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  smsMarketingSignUpForm: SmsMarketingSignUpForm;
  apiError?: ApolloError;
  setApiError: (error?: ApolloError) => void;
  success: boolean;
  setSuccess: (value: boolean) => void;
  inferredUserCountryCode?: string;
}

export const MultiStepSignUpContext =
  React.createContext<MultiStepSignUpContextValue | null>(null);

interface OnSignUpSubmitProps {
  smsFormData: {
    firstName?: string;
    lastName?: string;
    mobile?: string;
    countryCode?: string;
  };
  setSubmitting: (value: boolean) => void;
}

export const MultiStepSignUpContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [newUser, setNewUser] = useState<User | undefined>();
  const { user: loggedInUser } = useContext(AuthContext);
  const user = loggedInUser || newUser;

  const { inferredUserCountryCode, inferredCountryIsTopGlobalCountry } =
    useInferredUserCountryCode({ user });

  const [success, setSuccess] = useState(false);
  const [apiError, setApiError] = useState<ApolloError | undefined>(undefined);
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const [updateUser] = useMutation<UpdateUserProfileData>(UPDATE_USER_PROFILE);

  const smsMarketingSignUpForm = useCallback(
    ({ formContext, hide = false, onSuccess }) => {
      const initialFormValues = {
        countryCode: inferredUserCountryCode || '',
        mobile: user?.mobile?.numberBody || '',
      };

      const shouldDisplaySmsOptIn =
        !hide &&
        !!user &&
        (!user.mobile?.numberBody || !user.optInFanMarketingSmses) &&
        !!inferredUserCountryCode &&
        !!inferredCountryIsTopGlobalCountry;

      const onSmsSignUpSubmit = async ({
        smsFormData,
        setSubmitting,
      }: OnSignUpSubmitProps) => {
        setSubmitting(true);

        const analyticsProps = {
          form_context: formContext,
          is_standalone_form: true,
          default_country: inferredUserCountryCode,
          mobile: smsFormData.mobile,
          country_code: smsFormData.countryCode,
        };

        const variables = {
          mobile: {
            numberBody: smsFormData.mobile,
            countryCode: smsFormData.countryCode?.toUpperCase(),
          },
          optInFanMarketingSmses: true,
        };

        try {
          const response = await updateUser({
            variables,
          });

          if (response?.data?.updateUserProfile?.success) {
            setSubmitting(false);
            setSuccess(true);
            setApiError(undefined);
            trackAnalyticsEvent('SMS Opt-In Succeeded', analyticsProps);
            onSuccess && onSuccess();
          } else {
            trackAnalyticsEvent('SMS Opt-In Failed', {
              ...analyticsProps,
              error: undefined,
            });
            setSubmitting(false);
          }
        } catch (e) {
          const apolloError = e as ApolloError;
          setApiError(apolloError);
          trackAnalyticsEvent('SMS Opt-In Failed', {
            ...analyticsProps,
            error: apolloError.message,
          });
          setSubmitting(false);
        }
      };

      return {
        onSmsSignUpSubmit,
        initialFormValues,
        shouldDisplaySmsOptIn,
      };
    },
    [user, newUser, inferredUserCountryCode, inferredCountryIsTopGlobalCountry]
  );

  const multiStepSignUpContextValue = useMemo(
    () => ({
      newUser,
      setNewUser,
      smsMarketingSignUpForm,
      apiError,
      setApiError,
      success,
      setSuccess,
      inferredUserCountryCode,
    }),
    [
      newUser,
      inferredUserCountryCode,
      inferredCountryIsTopGlobalCountry,
      smsMarketingSignUpForm,
      apiError,
      success,
      setSuccess,
    ]
  );

  return (
    <MultiStepSignUpContext.Provider value={multiStepSignUpContextValue}>
      {children}
    </MultiStepSignUpContext.Provider>
  );
};

export function useMultiStepSignUpContext(): MultiStepSignUpContextValue {
  const multipStepSignUpContext = React.useContext(MultiStepSignUpContext);

  if (multipStepSignUpContext === null) {
    throw new Error(
      'useMultiStepSignUpContext must be used within a MultiStepSignUpContextProvider'
    );
  }

  return multipStepSignUpContext;
}
