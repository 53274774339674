import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { Icon } from 'app/shared/components/atoms';
import { Box, Typography } from 'app/shared/components/atoms';
import { ReactComponent as AlertCircle } from 'icons/alert-circle.svg';

const ErrorIcon = () => {
  const { colors } = useCurrentTheme();

  return (
    <Icon
      size="xs"
      display="inline-flex"
      icon={AlertCircle}
      strokeColor={colors.messaging.error.dark}
    />
  );
};

export const FormErrorMessage: React.FC<{
  displayIcon?: boolean;
  'data-qaid'?: string;
}> = ({
  children,
  displayIcon = true,
  'data-qaid': qaId = 'form-error-message',
}) => {
  const { colors } = useCurrentTheme();

  return (
    <Box alignItems="flex-start" gap={1} data-qaid={qaId}>
      <Box mt={1} alignContent="center">
        {displayIcon && <ErrorIcon />}
      </Box>
      <Typography lineHeight="100%" color={colors.messaging.error.dark}>
        {children}
      </Typography>
    </Box>
  );
};
