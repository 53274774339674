import React from 'react';
import { ThemeProvider } from 'styled-components';

import { themeCatalog } from 'app/shared/theme/themeCatalog';

const ThemeWrapper: React.FC<{ themeName: string; children: any }> = ({
  themeName,
  children,
}) => {
  const themeToUse = themeCatalog[themeName];

  if (!themeToUse) {
    return null;
  }

  return <ThemeProvider theme={themeToUse}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
