import React from 'react';
import styled from 'styled-components';

import { useButtonClickBehavior } from 'app/shared/utils/useButtonClickBehavior';
import {
  withDimensionsCSS,
  WithDimensionsProps,
} from 'app/shared/components/styleUtils';
import {
  withFlexCSS,
  WithFlexProps,
  withFontCSS,
  WithFontProps,
  withLinkStyleCSS,
  WithLinkStyleProps,
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';

export interface ActionLinkProps
  extends React.ComponentPropsWithoutRef<'button'>,
    WithSpacingProps,
    WithFlexProps,
    WithDimensionsProps,
    WithFontProps,
    React.AriaAttributes,
    WithLinkStyleProps {
  'data-qaid'?: string;
}

// Keep withFlexCSS after withLinkStyleCSS
const StyledButton = styled.button<ActionLinkProps>`
  padding: 0;

  ${withDimensionsCSS};
  ${withSpacingCSS};
  ${withFontCSS};
  ${withLinkStyleCSS};
  ${withFlexCSS};
`;

export const ActionLink: React.FC<ActionLinkProps> = ({
  children,
  onClick,
  ...props
}) => {
  const { buttonRef, handleClick } = useButtonClickBehavior({ onClick });

  return (
    <StyledButton
      ref={buttonRef}
      onClick={handleClick}
      textDecoration="underline"
      textTransform="none"
      {...props}
    >
      {children}
    </StyledButton>
  );
};
