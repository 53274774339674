import { useCallback, useContext } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import Cookies from 'js-cookie';

import { sendGA4Event } from 'app/lib/google';
import { AuthContext } from 'app/shared/context/Auth';

import { usePixelTracker } from './usePixelTracker';

export const useTrackAnalyticsEvent = (
  analytics: AnalyticsBrowser,
  eventsCalled: string[],
  setEventsCalled: (events: string[]) => void
) => {
  const { visitorType } = useContext(AuthContext);
  const trackPixels = usePixelTracker();

  return useCallback(
    (
      eventDescription: string,
      options: {},
      trackOnce = false,
      eventIdentifier = ''
    ) => {
      if (trackOnce) {
        const eventIdentifierString = eventIdentifier.toString();
        if (eventsCalled.includes(eventDescription + eventIdentifierString)) {
          return;
        } else {
          setEventsCalled([
            ...eventsCalled,
            eventDescription + eventIdentifierString,
          ]);
        }
      }

      const cookies = Cookies.get();
      const {
        ttclid,
        tt_test_id,
        ScCid, // Snapchat cookie that we set based on query params
        _scid, // Snapchat cookie that may or may not have gotten automatically set
      } = cookies;

      const ga4SessionKey = Object.keys(cookies).find((key: string) =>
        /^_ga_/.test(key)
      );
      const ga_session_id =
        ga4SessionKey &&
        cookies[ga4SessionKey]?.match(/GS\d\.\d\.(?<session_id>\d+)\..*/)?.[1];

      const analyticsProperties = {
        ...options,
        visitor_type: visitorType,
        ttclid,
        tt_test_id,
        ga_session_id,
        ScCid,
        _scid,
      };

      analytics.track(eventDescription, analyticsProperties);
      trackPixels(eventDescription, analyticsProperties);
      sendGA4Event(eventDescription, analyticsProperties);
    },
    [analytics, visitorType, eventsCalled]
  );
};
