import React, { useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import messagesLoader from 'src/locales/messagesLoader';

import { IntlContext } from 'app/shared/context/Intl';

function withLocaleMessages(
  PassedComponent: React.ComponentType<any>,
  fileName: string,
  initialMessages?: object
) {
  return (props: any) => {
    const {
      currentLanguage: { key: currentLanguageKey },
    } = useContext(IntlContext);
    const [messages, setMessages] = useState<any>(initialMessages);

    messagesLoader[currentLanguageKey](fileName).then(
      (importedLocaleFile: { default: object }) => {
        setMessages(importedLocaleFile.default);
      }
    );

    if (!messages) {
      return null;
    }

    return (
      <IntlProvider locale={currentLanguageKey} messages={messages}>
        <PassedComponent {...props} />
      </IntlProvider>
    );
  };
}

export default withLocaleMessages;
