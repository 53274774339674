const getEnvironment = () => process.env.APP_ENV || 'local';

export const isEnvironmentProdOrLocal = () =>
  getEnvironment() === 'live' || getEnvironment() === 'local';

export const isReviewApp = () => {
  const env = getEnvironment();

  return (
    env !== 'local' &&
    env !== 'live' &&
    env !== 'staging' &&
    process.env.API_URL?.includes('sofar-client-review.netlify.app')
  );
};
