import React from 'react';

import { NEW_HELP_REQUEST_URL } from 'app/shared/utils/siteUrls';
import {
  WithFontProps,
  WithLinkStyleProps,
} from 'app/shared/components/styleUtils';

import { NavigationLink } from './NavigationLink';

export const ZendeskLink = (
  props: React.PropsWithChildren<WithLinkStyleProps & WithFontProps>
) => (
  <NavigationLink
    href={NEW_HELP_REQUEST_URL}
    openInNewTab
    variant="primary"
    {...props}
  />
);
