import { Dimensions } from 'app/shared/theme/common/typings';

export const dimensions: Dimensions = {
  input: {
    height: '40px',
  },
  button: {
    height: '40px',
  },
  maxPageWidth: 1176,
  maxCheckoutMainContentWidth: 624,
  horizontalPadding: {
    page: [4, 8, 8, 8],
    pageGrid: [0, 8, 8, 8],
    pageGridItem: [4, 0, 0, 0],
  },
};
