import React, { StrictMode } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import SofarApolloProvider from 'src/apollo/SofarApolloProvider';

import CookieInitializer from 'app/lib/CookieInitializer';
import { initializeDatadog } from 'app/lib/datadog';
import ErrorBoundary from 'app/lib/ErrorBoundary';
import { initializeGA4, initializeGTM } from 'app/lib/google';
import ThirdPartyScriptLoader from 'app/lib/ThirdPartyScriptLoader';
import AnalyticsProvider from 'app/shared/context/Analytics';
import AuthProvider from 'app/shared/context/Auth';
import CityProvider from 'app/shared/context/City';
import FeatureFlagsProvider from 'app/shared/context/FeatureFlags';
import PermissionsProvider from 'app/shared/context/Permissions';

import App from './App';
import MainRootError from './MainRootError';

initializeGTM();

initializeGA4();

initializeDatadog();

const ROOT = document.getElementById('__app') as HTMLElement;

const onLogError = (error: any) => {
  console.log(`ERROR CAUGHT AT ROOT COMPONENT: ${error}`);
};

const ErrorComponent = () => {
  return <MainRootError />;
};

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary ErrorComponent={ErrorComponent} onLogError={onLogError}>
      <BrowserRouter>
        <CookieInitializer>
          <ThirdPartyScriptLoader>
            <SofarApolloProvider>
              <HelmetProvider>
                <GoogleOAuthProvider
                  clientId={process.env.GOOGLE_CLIENT_ID || ''}
                >
                  <AuthProvider>
                    <PermissionsProvider>
                      <CityProvider>
                        <AnalyticsProvider>
                          <FeatureFlagsProvider>
                            <App />
                          </FeatureFlagsProvider>
                        </AnalyticsProvider>
                      </CityProvider>
                    </PermissionsProvider>
                  </AuthProvider>
                </GoogleOAuthProvider>
              </HelmetProvider>
            </SofarApolloProvider>
          </ThirdPartyScriptLoader>
        </CookieInitializer>
      </BrowserRouter>
    </ErrorBoundary>
  </StrictMode>,
  ROOT
);
