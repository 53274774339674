import React, { useState } from 'react';
import styled from 'styled-components';

// TODO: Remove need to import theme directly here, instead use theme context within styled components below -
// when we tried this, the NotifyProvider was raising errors that it didn't have access to 'theme', so we need
// to figure that out and fix it somehow
import { adminManualCSSTheme as theme } from 'app/shared/theme';
import { Portal } from 'app/shared/components/atoms/Portal';
import { Spacer } from 'app/shared/components/atoms/Spacer';
import { Snackbar } from 'app/shared/components/molecules/SnackbarManualCSS';

interface Props {
  children: any;
}

const NOTIFICATION_TIMEOUT = 3000;

interface NotifyContextValues {
  addMessage: (message: string, notificationTimeout?: number) => void;
}

const NotificationContainer = styled.div`
  position: fixed;
  top: 0px;
  padding: 15px;
  width: 100%;
  z-index: 101;
  pointer-events: none;

  ${theme.media.xs`
      width: 80%;
    `};

  ${theme.media.md`
      width: 100%;
    `};
`;

interface Message {
  message: string;
  time: number;
  id: number;
}

export const NotifyContext = React.createContext<NotifyContextValues>({
  addMessage: () => {},
});

const NotifyProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);

  const removeMessage = (messageId: number) => {
    setMessages(messages.filter((m: Message) => m.id !== messageId));
  };

  const mapSnackbar = () =>
    messages.map((e: Message) => {
      if (e.message === 'Visa Last Four') {
        return null;
      }
      return (
        <React.Fragment key={e.id}>
          <Spacer mb={1} />
          <Snackbar data-qaid="snackbar" onCancel={() => removeMessage(e.id)}>
            {e.message}
          </Snackbar>
        </React.Fragment>
      );
    });

  // Unfortunately, apollo client adds some additional formatting to the error
  // message that ApolloError/ApolloClient does not allow us to override/control
  // at this moment in time
  // (https://github.com/apollographql/apollo-feature-requests/issues/46#issuecomment-528046940).
  // As a simplified workaround, we are going to manually remove it from the error
  // message string if it exists.
  const GRAPHQL_ERROR_HEADER = 'GraphQL error: ';
  const GENERAL_ERROR_HEADER = 'Error: ';
  const removeGraphqlHeader = (errorMessage: string) =>
    errorMessage &&
    errorMessage
      .replace(GRAPHQL_ERROR_HEADER, '')
      .replace(GENERAL_ERROR_HEADER, '');

  function addMessage(
    message: string,
    notificationTimeout = NOTIFICATION_TIMEOUT
  ) {
    const existing = messages.find((m: Message) => m.message === message);

    if (!existing) {
      const formattedMessage = removeGraphqlHeader(message);
      const newMessage = {
        message: formattedMessage,
        time: new Date().getTime(),
        id: Math.random(),
      };

      setMessages([...messages, newMessage]);
      setTimeout(() => {
        removeMessage(newMessage.id);
      }, notificationTimeout);
    }
  }

  const contextValue = {
    messages,
    addMessage,
  };

  if (typeof window === 'object') {
    return (
      <NotifyContext.Provider value={contextValue}>
        {children}
        <Portal dom={document.body}>
          <NotificationContainer>{mapSnackbar()}</NotificationContainer>
        </Portal>
      </NotifyContext.Provider>
    );
  }

  return null;
};

export default NotifyProvider;
