import React from 'react';
import styled, { css } from 'styled-components';

export interface StreamlineIconProps {
  icon: any;
  size?: number;
  stroke?: string;
  fill?: string;
  background?: string;
  display?: string;
  svgTitle?: string;
  className?: string;
  borderRadius?: number;
  hoverStroke?: string;
  height?: number;
  width?: number;
}

const Wrapper = styled.span<{
  stroke?: string;
  hoverStroke?: string;
  fill?: string;
  background?: string;
  display?: string;
  width?: number;
  height?: number;
  borderRadius?: number;
}>`
  ${({
    theme,
    stroke,
    hoverStroke,
    fill,
    display,
    width,
    height,
    borderRadius,
    background,
  }) => css`
    width: ${width}px;
    height: ${height}px;
    display: ${display || undefined};
    stroke: ${stroke || theme.colors.backToBlack};
    &:hover {
      path {
        stroke: ${hoverStroke || theme.colors.backToBlack};
      }
    }
    fill: ${fill || undefined};
    background: ${background || undefined};
    path {
      stroke: ${stroke || theme.colors.backToBlack};
      fill: ${fill || undefined};
    }
    border-radius: ${borderRadius ? `${borderRadius}px` : undefined};
  `}
`;

export const StreamlineIcon = ({
  icon,
  size = 16,
  stroke,
  hoverStroke,
  fill,
  display,
  svgTitle,
  className,
  borderRadius,
  background,
  height,
  width,
}: StreamlineIconProps) => (
  <Wrapper
    stroke={stroke}
    hoverStroke={hoverStroke}
    fill={fill}
    display={display}
    height={height || size}
    width={width || size}
    className={className}
    borderRadius={borderRadius}
    background={background}
  >
    {icon &&
      React.createElement(icon, {
        width: width || size,
        height: height || size,
        title: svgTitle,
      })}
  </Wrapper>
);
