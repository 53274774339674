import { CSSProperties } from 'react';
import { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';

export interface WithPositionProps {
  position?: CSSProperties['position'];
  top?: CSSProperties['top'];
  right?: CSSProperties['right'];
  bottom?: CSSProperties['bottom'];
  left?: CSSProperties['left'];
  alignSelf?: CSSProperties['alignSelf'];
}

interface WithPositionCSSProps extends WithPositionProps {
  theme: CommonTheme;
}

// top, right, bottom, and left are ignored unless position is also supplied
export const withPositionCSS = ({
  position,
  top,
  right,
  bottom,
  left,
  alignSelf,
}: WithPositionCSSProps) => css`
  ${
    position &&
    `
      position: ${position};

      ${top && `top: ${top}`};
      ${right && `right: ${right}`};
      ${bottom && `bottom: ${bottom}`};
      ${left && `left: ${left}`};
    `
  }

  ${alignSelf && `align-self: ${alignSelf};`}
`;
