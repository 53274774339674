import styled, { css, keyframes } from 'styled-components';

import {
  withDimensionsCSS,
  WithDimensionsProps,
} from 'app/shared/components/styleUtils';
import {
  withBorderCSS,
  WithBorderProps,
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';
export interface LoadingBlockProps
  extends WithSpacingProps,
    WithBorderProps,
    WithDimensionsProps {}

const animation = keyframes`
  0%{
    background-position: 0px 0
  }
  100%{
    background-position: 4000px 0
  }
`;

export const LoadingBlock = styled.div<LoadingBlockProps>`
  ${({ theme }) => css`
    border-radius: 2px;

    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${animation};
    animation-timing-function: linear;
    background-color: ${theme.colors.primary.white.base};
    background: linear-gradient(
      to right,
      ${theme.colors.primary.white.lighter} 8%,
      ${theme.colors.accent.primaryAccent.lightest} 18%,
      ${theme.colors.primary.white.lighter} 33%
    );
    background-size: 4000px 104px;
    position: relative;
  `}
  ${withSpacingCSS};
  ${withBorderCSS};
  ${withDimensionsCSS};
`;
