import { ZIndex } from 'app/shared/theme/common/typings';

export const zIndex: ZIndex = {
  stageBar: 99, // Must be less than StickyContainer
  portalUnderlay: 999,
  drawer: 1000,
  snackbar: 1001,
  modal: 1002,
  mobileNav: 1003,
  navbar: 1004,
  dropdown: 1010,
};
