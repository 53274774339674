import React from 'react';
import { ApolloError } from 'apollo-client';

import { snakeCaseToCamel } from 'app/shared/utils/string';
import { useFeatureFlags } from 'app/shared/utils/useFeatureFlags';
import { useFeatureFlagsForExperiments } from 'app/shared/utils/useFeatureFlags';
import { useFeatureFlagsForCurrentPath } from 'app/shared/utils/useFeatureFlagsForCurrentPath';

interface Props {
  children: any;
}

interface FeatureFlagsContextValues {
  featureFlagsLoaded?: string[];
  featureFlagsEnabled?: Record<string, boolean>;
  error?: ApolloError;
  loading?: boolean;
  called?: boolean;
  queryRequired?: boolean;
}

export const FeatureFlagsContext =
  React.createContext<FeatureFlagsContextValues>({
    featureFlagsLoaded: [],
    featureFlagsEnabled: {},
    error: undefined,
    loading: undefined,
    called: undefined,
    queryRequired: undefined,
  });

const featureFlagsWithExperiment: string[] = [];

const anyFeatureFlagsWithExperiment = (featureFlagsToLoad: string[]) =>
  featureFlagsToLoad.some((flag: string) =>
    featureFlagsWithExperiment.includes(flag)
  );

const getFeatureFlagsLoaded = (featureFlagsToLoad: string[]) =>
  featureFlagsToLoad.map((flag: string) => snakeCaseToCamel(flag));

const getContextValue = (
  featureFlagsRetrievalInfo: any,
  featureFlagsToLoad: string[]
) => {
  return {
    // Array of feature flags in camelCase
    featureFlagsLoaded: getFeatureFlagsLoaded(featureFlagsToLoad),
    // Object of feature flags in camelCase and boolean values
    featureFlagsEnabled: featureFlagsRetrievalInfo.featureFlagsEnabled,
    error: featureFlagsRetrievalInfo.error,
    loading: featureFlagsRetrievalInfo.loading,
    called: featureFlagsRetrievalInfo.called,
    queryRequired: featureFlagsRetrievalInfo.queryRequired,
  };
};

interface ProviderSubComponentProps {
  featureFlagsToLoad: string[];
  children: any;
}

const ProviderWithExperiment: React.FC<ProviderSubComponentProps> = ({
  featureFlagsToLoad,
  children,
}) => {
  const featureFlagsRetrievalInfo =
    useFeatureFlagsForExperiments(featureFlagsToLoad);

  const contextValue = getContextValue(
    featureFlagsRetrievalInfo,
    featureFlagsToLoad
  );

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const ProviderWithoutExperiment: React.FC<ProviderSubComponentProps> = ({
  featureFlagsToLoad,
  children,
}) => {
  const featureFlagsRetrievalInfo = useFeatureFlags(featureFlagsToLoad);

  const contextValue = getContextValue(
    featureFlagsRetrievalInfo,
    featureFlagsToLoad
  );

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const FeatureFlagsProvider: React.FC<Props> = ({ children }) => {
  const featureFlagsToLoad = useFeatureFlagsForCurrentPath();

  const usingExperiment = anyFeatureFlagsWithExperiment(featureFlagsToLoad);

  return (
    <>
      {usingExperiment ? (
        <ProviderWithExperiment featureFlagsToLoad={featureFlagsToLoad}>
          {children}
        </ProviderWithExperiment>
      ) : (
        <ProviderWithoutExperiment featureFlagsToLoad={featureFlagsToLoad}>
          {children}
        </ProviderWithoutExperiment>
      )}
    </>
  );
};

export default FeatureFlagsProvider;
