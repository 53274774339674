import { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';

export interface WithBackgroundProps {
  backgroundColor?: string;
  backgroundPosition?: string;
  backgroundImage?: string | string[];
  hoverBackgroundColor?: string;
}

interface WithBackgroundCSSProps extends WithBackgroundProps {
  theme: CommonTheme;
}

export const withBackgroundCSS = ({
  theme,
  backgroundColor,
  backgroundImage,
  backgroundPosition,
  hoverBackgroundColor,
}: WithBackgroundCSSProps) => css`
  ${backgroundPosition && `background-position: ${backgroundPosition};`}
  ${backgroundColor && `background: ${backgroundColor};`}
  ${
    hoverBackgroundColor &&
    `&:hover {
      background-color: ${hoverBackgroundColor};
    }`
  }

  ${theme.media.mobile`
    ${
      backgroundImage &&
      Array.isArray(backgroundImage) &&
      `
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url(${backgroundImage[0]});
    `
    }
  `}

  ${theme.media.tablet`
    ${
      backgroundImage &&
      Array.isArray(backgroundImage) &&
      `
      background-image: url(${backgroundImage[1]});
    `
    }
  `}

  ${theme.media.smallDesktop`
    ${
      backgroundImage &&
      Array.isArray(backgroundImage) &&
      `
      background-image: url(${backgroundImage[2]});
    `
    }
  `}

  ${theme.media.largeDesktop`
    ${
      backgroundImage &&
      Array.isArray(backgroundImage) &&
      `
      background-image: url(${backgroundImage[3]});
    `
    }
  `}

  ${
    backgroundImage &&
    !Array.isArray(backgroundImage) &&
    `background-image: url(${backgroundImage}); background-repeat: no-repeat; background-size: cover;`
  }
`;
