import React from 'react';

import { Typography } from 'app/shared/components/atoms';

interface Props {
  detailText?: React.ReactNode | string;
  as?: React.ElementType;
}

export const ModalDetailsSection = ({ detailText, as = 'p' }: Props) => {
  if (!detailText) {
    return null;
  }

  return (
    <Typography as={as} fontSize="fontSize75">
      {detailText}
    </Typography>
  );
};
