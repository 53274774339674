import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import Cookies from 'js-cookie';

import google from 'app/lib/google';
import { usePermissionContextsForCurrentPath } from 'app/shared/utils/usePermissionContextsForCurrentPath';
import { Permission } from 'app/shared/typings/permissions';
import {
  DestroySessionResponse,
  GetSessionData,
  VisaAuthData,
} from 'app/shared/typings/sessions';
import { User } from 'app/shared/typings/users';
import { DESTROY_SESSION } from 'app/shared/graphql/sessions/mutations';
import {
  GET_SESSION,
  GET_SESSION_WITH_PERMISSIONS,
} from 'app/shared/graphql/sessions/queries';

interface Props {
  children: any;
}

export interface SessionMessage {
  text: string | undefined;
  link: string | undefined;
  linkText: string | undefined;
}

export interface AuthContextValues {
  user: User | null;
  loggedIn: boolean;
  magicTokenLogin: boolean;
  signIn: () => boolean;
  signOut: any;
  reloadSession: () => boolean;
  userIpCountryCode: string | undefined;
  sessionMessage?: SessionMessage;
  loading?: boolean;
  error?: ApolloError;
  visa: VisaAuthData;
  visitorType: string;
  permissions?: Permission[];
}

export const AuthContext = React.createContext<AuthContextValues>({
  user: null,
  loggedIn: false,
  magicTokenLogin: false,
  signIn: () => false,
  signOut: () => false,
  reloadSession: () => false,
  userIpCountryCode: undefined,
  sessionMessage: undefined,
  visa: { eligible: false },
  visitorType: 'fan',
  permissions: undefined,
});

// Clears the session cookie because sometimes the BE fails to clear it
function clearSessionCookie() {
  Cookies.remove('_session_id');
}

function recordGoogleMetrics(contextValue: AuthContextValues) {
  if (contextValue.loggedIn && contextValue.user) {
    const displayName = `${contextValue.user.firstName} ${contextValue.user.lastName}`;
    const visitorType =
      contextValue.user.visitorType || contextValue.visitorType;

    // We have both userID and userId as different tags have been created with each of these over time
    // TODO: To migrate all instances of userId to userID
    google.dataLayer({
      userId: contextValue.user.id,
      userID: contextValue.user.id,
      userName: displayName,
      userEmail: contextValue.user.email,
      visitorType,
    });
  }
}

const getSessionQueryAndVariables = (permissionContexts?: string[]) => {
  if (permissionContexts && permissionContexts.length > 0) {
    return {
      query: GET_SESSION_WITH_PERMISSIONS,
      variables: { permissionContexts },
    };
  } else {
    return {
      query: GET_SESSION,
      variables: {},
    };
  }
};

const AuthProvider: React.FC<Props> = ({ children }) => {
  const permissionContexts = usePermissionContextsForCurrentPath();

  const { query: getSessionQuery, variables: getSessionVariables } =
    getSessionQueryAndVariables(permissionContexts);

  const {
    data: sessionData,
    loading: getSessionLoading,
    error: getSessionError,
    refetch,
  } = useQuery<GetSessionData>(getSessionQuery, {
    fetchPolicy: 'cache-and-network',
    variables: getSessionVariables,
  });

  const session = sessionData?.session;

  const [
    destroySession,
    { loading: destroySessionLoading, error: destroySessionError },
  ] = useMutation<DestroySessionResponse>(DESTROY_SESSION);

  function signIn() {
    if (!sessionData || sessionData.session.loggedIn) {
      return false;
    }

    return reloadSession();
  }

  async function signOut() {
    await destroySession();
    clearSessionCookie();
  }

  function reloadSession() {
    refetch()
      .then((data) => {
        if (data.data.session.loggedIn) {
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });

    return false;
  }

  const userIpCountryCode =
    sessionData?.session?.userIpGeolocation?.countryCode;

  const sessionMessageValues = {
    text: session?.message,
    link: session?.messageMoreInfoLink,
    linkText: session?.messageMoreInfoText,
  };

  const visaValues = session?.visa
    ? {
        cardId: session?.visa.cardId || undefined,
        cardLast4: session?.visa.cardLast4 || undefined,
        eligible: session?.visa.eligible || false,
        eligibilityLevel: session?.visa.eligibilityLevel || undefined,
        visaVerifiedAt: session?.visa.visaVerifiedAt || undefined,
        cardType: session?.visa.cardType || undefined,
      }
    : { eligible: false };

  const contextValue: AuthContextValues = {
    user: session?.user || null,
    loggedIn: !!session?.loggedIn,
    magicTokenLogin: session?.magicTokenAuthentication || false,
    signIn,
    signOut,
    reloadSession,
    userIpCountryCode,
    sessionMessage: sessionMessageValues,
    loading: getSessionLoading || destroySessionLoading,
    error: getSessionError || destroySessionError,
    visa: visaValues,
    visitorType: session?.visitorType || 'fan',
    permissions: session?.permissions,
  };

  recordGoogleMetrics(contextValue);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
