import React, { useContext, useMemo } from 'react';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';

const libraries = ['places'] as Libraries;

interface GoogleMapsContextValues {
  isGoogleMapsLoaded: boolean;
  googleMapsError?: Error;
}

export const GoogleMapsContext = React.createContext<GoogleMapsContextValues>({
  isGoogleMapsLoaded: false,
});

const GoogleMapsProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: String(process.env.GOOGLE_MAP_API),
    libraries,
  });

  const contextValue = useMemo(
    () => ({
      isGoogleMapsLoaded: isLoaded,
      googleMapsError: loadError,
    }),
    [isLoaded, loadError]
  );

  return (
    <GoogleMapsContext.Provider value={contextValue}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export function useGoogleMapsContext() {
  const value = useContext(GoogleMapsContext);

  if (!value) {
    throw new Error(
      'useGoogleMapsContext must be called within a child or grandchild of a Google Maps Context Provider!'
    );
  }
  return value;
}

export default GoogleMapsProvider;
