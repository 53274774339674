import React from 'react';

import { useHotjar } from 'app/shared/utils/useHotjar';

function withHotjar(PassedComponent: React.ComponentType<any>) {
  const WrappedComponent: React.FC = (props: any) => {
    useHotjar();
    return <PassedComponent {...props} />;
  };

  return WrappedComponent;
}

export default withHotjar;
