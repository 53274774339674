import React, { createContext, useMemo, useState } from 'react';
import { AnalyticsBrowser, ID, UserTraits } from '@segment/analytics-next';

import { User } from 'app/shared/typings';

import { useAnonymousId } from './useAnonymousId';
import { useFireSegmentIdentify } from './useFireSegmentIdentify';
import { useSegmentIdentify } from './useSegmentIdentify';
import { useSegmentPage } from './useSegmentPage';
import { useSegmentReset } from './useSegmentReset';
import { useTrackAnalyticsEvent } from './useTrackAnalyticsEvent';

export interface AnalyticsContextValues {
  trackAnalyticsEvent: (
    eventDescription: string,
    options: {},
    trackOnce?: boolean,
    eventIdentifier?: string
  ) => void;
  segmentIdentify: (
    user?: User | null | undefined,
    identifyTraits?: UserTraits
  ) => void;
  segmentPage: (title: string) => void;
  segmentResetAnonymousId: () => void;
  anonymousId: ID;
  segmentInitialized: boolean;
  segmentFailedToInitialize: boolean;
}

export const SEGMENT_WRITE_KEY = process.env.SEGMENT_WRITE_KEY as string;
// We can use the same CDN url for all environments, the write key will dictate
// any environment specific info to pull down when used by AnalyticsBrowser.
export const SEGMENT_CDN_URL =
  process.env.SEGMENT_CDN_URL || 'https://analytics.sofarsounds.com';

export const AnalyticsContext = createContext<AnalyticsContextValues | null>(
  null
);

const AnalyticsProvider: React.FC = ({ children }) => {
  const analytics = useMemo(
    () =>
      AnalyticsBrowser.load(
        {
          writeKey: SEGMENT_WRITE_KEY,
          cdnURL: SEGMENT_CDN_URL,
        },
        {
          cookie: {
            maxage: 90, // 90 days
          },
        }
      ),
    []
  );
  const [segmentFailedToInitialize, setSegmentFailedToInitialize] =
    useState(false);
  const [segmentLoadedSuccessfully, setSegmentLoadedSuccessfully] =
    useState(false);
  const [eventsCalled, setEventsCalled] = useState<string[]>([]);
  const trackAnalyticsEvent = useTrackAnalyticsEvent(
    analytics,
    eventsCalled,
    setEventsCalled
  );
  const segmentIdentify = useSegmentIdentify(analytics);
  const segmentPage = useSegmentPage(analytics, setEventsCalled);
  const segmentReset = useSegmentReset(analytics);
  const anonymousId = useAnonymousId(analytics);

  analytics.ready(function () {
    setSegmentLoadedSuccessfully(true);
  });

  analytics.catch(() => setSegmentFailedToInitialize(true));

  useFireSegmentIdentify(segmentIdentify, segmentReset);

  const segmentInitialized =
    segmentLoadedSuccessfully || (analytics && !!analytics.track);

  const contextValue = useMemo<AnalyticsContextValues>(
    () => ({
      trackAnalyticsEvent,
      segmentIdentify,
      segmentPage,
      segmentResetAnonymousId: segmentReset,
      anonymousId,
      segmentInitialized,
      segmentFailedToInitialize,
    }),
    [
      trackAnalyticsEvent,
      segmentIdentify,
      segmentPage,
      segmentReset,
      anonymousId,
      segmentInitialized,
      segmentFailedToInitialize,
    ]
  );

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
