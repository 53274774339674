import { useCurrentTheme } from 'app/shared/theme';
import useWindowSize from 'app/shared/utils/useWindowSize';

export const useBreakpoint = () => {
  const { breakPoints } = useCurrentTheme();
  const [width] = useWindowSize();

  if (width >= breakPoints.largeDesktop) {
    return 'largeDesktop';
  } else if (width >= breakPoints.smallDesktop) {
    return 'smallDesktop';
  } else if (width >= breakPoints.tablet) {
    return 'tablet';
  }

  return 'mobile';
};

export const useTShirtSizeBreakpoint = () => {
  const { breakPoints } = useCurrentTheme();
  const [width] = useWindowSize();

  if (width < breakPoints.sm) {
    return 'xs';
  } else if (width < breakPoints.md) {
    return 'sm';
  } else if (width < breakPoints.lg) {
    return 'md';
  } else if (width < breakPoints.xl) {
    return 'lg';
  }

  return 'xl';
};

export function useIsBreakpointSize() {
  const currentBreakpoint = useBreakpoint();

  const isMobile = currentBreakpoint === 'mobile';
  const isTablet = currentBreakpoint === 'tablet';
  const isSmallDesktop = currentBreakpoint === 'smallDesktop';
  const isLargeDesktop = currentBreakpoint === 'largeDesktop';
  const isDesktop = isSmallDesktop || isLargeDesktop;

  return { isMobile, isTablet, isSmallDesktop, isLargeDesktop, isDesktop };
}

export function useIsTShirtSizeBreakpointSize() {
  const currentBreakpoint = useTShirtSizeBreakpoint();

  const isMobile = currentBreakpoint === 'xs' || currentBreakpoint === 'sm';
  const isTablet = currentBreakpoint === 'md';
  const isDesktop = currentBreakpoint === 'lg' || currentBreakpoint === 'xl';

  return { isMobile, isTablet, isDesktop };
}
