import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

// Returns a theme (not just the name but the theme itself) -
// for use in places where we need the current theme outside of a
// styled component definition, usually for passing colors as props
// into components
const useCurrentTheme = () => {
  return useContext(ThemeContext);
};

export default useCurrentTheme;
