import React, { useContext, useRef } from 'react';

import { useAnalyticsContext } from 'app/shared/utils';
import { AuthContext } from 'app/shared/context/Auth';

interface UseButtonClickEventsProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useButtonClickBehavior({
  onClick,
  isLoading = false,
  setIsLoading,
}: UseButtonClickEventsProps = {}) {
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const { visitorType } = useContext(AuthContext);
  const buttonRef = useRef<HTMLButtonElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    const buttonNode = buttonRef.current;
    const wasLoading = isLoading;

    if (buttonNode) {
      setIsLoading && !wasLoading && setIsLoading(true);
      buttonNode.disabled = true;

      trackAnalyticsEvent('Button Clicked', {
        location: buttonNode.baseURI,
        text: buttonNode.textContent,
        dataQaid: buttonNode.dataset.qaid,
        visitor_type: visitorType,
      });

      buttonNode.disabled = false;
      setIsLoading && setIsLoading(wasLoading);
    }

    onClick && onClick(event);
  }

  return { buttonRef, handleClick };
}
