import { CountrySelectorDropdownStyleProps } from 'react-international-phone/build/components/CountrySelector/CountrySelectorDropdown';

import { useCurrentTheme } from 'app/shared/theme';

export function useReactIntlPhoneStyleProps(backgroundColor?: string) {
  const { colors, boxShadow, dimensions, fonts, fontSizes, ruler } =
    useCurrentTheme();

  const reusableFontProps = {
    fontSize: fontSizes.fontSize100.mobile,
    fontFamily: fonts.default,
  };

  const buttonContentWrapperStyle = {
    display: 'flex',
    gap: `${ruler[1]}px`,
  };

  const buttonStyle = {
    border: 'none',
    background: 'none',
    padding: '0',
    cursor: 'pointer',
  };

  const countrySelectorStyle = {
    display: 'flex',
    alignItems: 'center',
    height: dimensions.input.height,
  };

  const dialCodeStyleProps = {
    height: dimensions.input.height,
    border: '0',
    backgroundColor: 'inherit',
    ...reusableFontProps,
  };

  const dropdownArrowStyle = { margin: '0' };

  const dropdownStyleProps: CountrySelectorDropdownStyleProps = {
    style: {
      padding: 0,
      backgroundColor: backgroundColor || colors.primary.white.lightest,
      border: 'none',
      outline: 'none',
      margin: '0px',
      boxShadow: boxShadow.doubleShadow,
      top: dimensions.input.height,
      width: 'max-content',
    },
    listItemStyle: reusableFontProps,
    listItemFlagStyle: { height: `${ruler[8]}px` },
    listItemCountryNameStyle: reusableFontProps,
    listItemDialCodeStyle: reusableFontProps,
  };

  const flagStyle = { margin: '0', height: `${ruler[8]}px` };

  return {
    buttonContentWrapperStyle,
    buttonStyle,
    countrySelectorStyle,
    dialCodeStyleProps,
    dropdownArrowStyle,
    dropdownStyleProps,
    flagStyle,
  };
}
