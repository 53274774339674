import { SocialUrlKey } from 'app/shared/typings/socialUrls';
import { ReactComponent as Bandcamp } from 'icons/bandcamp.svg';
import { ReactComponent as Facebook } from 'icons/facebook.svg';
import { ReactComponent as Website } from 'icons/globe.svg';
import { ReactComponent as Instagram } from 'icons/instagram.svg';
import { ReactComponent as Soundcloud } from 'icons/soundcloud.svg';
import { ReactComponent as Spotify } from 'icons/spotify.svg';
import { ReactComponent as Bandcamp1 } from 'icons/streamline-regular/logos/music/bandcamp-1.svg';
import { ReactComponent as SocialMusicSoundcloud } from 'icons/streamline-regular/logos/music/social-music-soundcloud.svg';
import { ReactComponent as SocialMusicSpotify2 } from 'icons/streamline-regular/logos/music/social-music-spotify-2.svg';
import { ReactComponent as SocialInstagram1 } from 'icons/streamline-regular/logos/photos-images/social-instagram-1.svg';
import { ReactComponent as SocialMediaFacebook } from 'icons/streamline-regular/logos/social-medias/social-media-facebook.svg';
import { ReactComponent as SocialMediaTiktok } from 'icons/streamline-regular/logos/social-medias/social-media-tiktok.svg';
import { ReactComponent as SocialMediaTwitter } from 'icons/streamline-regular/logos/social-medias/social-media-twitter.svg';
import { ReactComponent as SocialVideoYoutubeClip } from 'icons/streamline-regular/logos/videos/social-video-youtube-clip.svg';
import { ReactComponent as BrowserPageAccount } from 'icons/streamline-regular/programing-apps-websites/website-development/browser-page-account.svg';
import { ReactComponent as Tiktok } from 'icons/tiktok.svg';
import { ReactComponent as Twitter } from 'icons/twitter.svg';
import { ReactComponent as Youtube } from 'icons/youtube.svg';

type SocialIconFilenames = {
  [key in SocialUrlKey]: string | null | undefined;
};

// Files in sofar-client/public/
export const socialIconFilenamesForManualCSSColor: SocialIconFilenames = {
  urlBandcamp: 'bandcamp.svg',
  urlFacebook: 'facebook.svg',
  urlInstagram: 'instagram.svg',
  urlSoundcloud: 'soundcloud.svg',
  urlSpotify: 'spotify.svg',
  urlTiktok: null,
  urlTwitter: 'twitter.svg',
  urlVKontacte: null,
  urlWebsite: 'website.svg',
  urlYoutube: 'youtube.svg',
  urlSupport: null,
};

// Files in sofar-client/public/
export const socialIconFilenamesForManualCSSWhite: SocialIconFilenames = {
  urlBandcamp: 'bandcamp-v2.svg',
  urlFacebook: 'facebook-v2.svg',
  urlInstagram: 'instagram-v2.svg',
  urlSoundcloud: 'soundcloud-v2.svg',
  urlSpotify: 'spotify-v2.svg',
  urlTiktok: 'tiktok-v2.svg',
  urlTwitter: 'twitter-v2.svg',
  urlVKontacte: null,
  urlWebsite: 'website-v2.svg',
  urlYoutube: 'youtube-v2.svg',
  urlSupport: null,
};

type SocialIcons = {
  [key in SocialUrlKey]: React.FC<
    React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
  > | null;
};

export const socialIconsForCommon: SocialIcons = {
  urlBandcamp: Bandcamp,
  urlFacebook: Facebook,
  urlInstagram: Instagram,
  urlSoundcloud: Soundcloud,
  urlSpotify: Spotify,
  urlTiktok: Tiktok,
  urlTwitter: Twitter,
  urlVKontacte: null,
  urlWebsite: Website,
  urlYoutube: Youtube,
  urlSupport: null,
};

export const socialIconsForAdminManualCSS: SocialIcons = {
  urlBandcamp: Bandcamp1,
  urlFacebook: SocialMediaFacebook,
  urlInstagram: SocialInstagram1,
  urlSoundcloud: SocialMusicSoundcloud,
  urlSpotify: SocialMusicSpotify2,
  urlTiktok: SocialMediaTiktok,
  urlTwitter: SocialMediaTwitter,
  urlVKontacte: null,
  urlWebsite: BrowserPageAccount,
  urlYoutube: SocialVideoYoutubeClip,
  urlSupport: null,
};
