import React, { useEffect, useRef, useState } from 'react';
import { ApolloError } from 'apollo-client';
import { FormikProps } from 'formik';
import {
  CountryIso2,
  CountrySelector,
  defaultCountries,
  DialCodePreview,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import { findValidationErrors } from 'app/shared/utils/findValidationErrors';
import {
  Box,
  InputHelperText,
  InputProps,
  TextInput,
  Typography,
} from 'app/shared/components/atoms';
import { Grid, GridItem } from 'app/shared/components/atoms';

import { useReactIntlPhoneStyleProps } from './useReactIntlPhoneStyleProps';

import 'react-international-phone/style.css';

interface Props extends InputProps, FormikProps<any> {
  label: string;
  helperText?: string | null;
  isDisabled?: boolean;
  inputBackgroundColor?: string;
  labelColor?: string;
  apiError?: ApolloError | Error;
  countryCodes?: string[];
  selectedCountryCode?: string;
  isRequired?: boolean;
  'data-qaid'?: string;
}

export const MobilePhoneField: React.FC<Props> = ({
  helperText,
  label,
  isSuccessful = false,
  isDisabled = false,
  className,
  backgroundColor,
  inputBackgroundColor,
  labelColor,
  values,
  errors,
  touched,
  handleBlur,
  countryCodes,
  apiError,
  setFieldValue,
  selectedCountryCode,
  invertColors,
  isRequired,
  'data-qaid': qaId = 'mobile',
  ...props
}) => {
  const { borderRadius, colors, ruler, dimensions } = useCurrentTheme();

  const {
    buttonContentWrapperStyle,
    buttonStyle,
    countrySelectorStyle,
    dialCodeStyleProps,
    dropdownArrowStyle,
    dropdownStyleProps,
    flagStyle,
  } = useReactIntlPhoneStyleProps(backgroundColor);

  const dropDownAndPrefixRef = useRef<HTMLDivElement>(null);

  const paddingLeft = Math.floor(
    dropDownAndPrefixRef?.current
      ? dropDownAndPrefixRef.current.clientWidth / 4 + 6
      : 22
  );

  const countries = countryCodes
    ? defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        return countryCodes.includes(iso2);
      })
    : defaultCountries;

  const { formatMessage } = useIntl();
  const [validationError, setValidationError] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    const validationErrors = findValidationErrors(apiError);
    if (
      validationErrors?.mobile ||
      apiError?.message?.includes('Mobile number is invalid')
    ) {
      setValidationError(
        formatMessage({ id: 'form.validations.mobile.invalid' })
      );
    }
  }, [apiError]);

  const errorMsg =
    (errors.mobile && touched.mobile && errors.mobile) ||
    (errors.countryCode && touched.countryCode && errors.countryCode) ||
    validationError;

  const hasError = !!errorMsg;

  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: selectedCountryCode || 'us',
      value: values.mobile,
      countries,
      onChange: ({ country, inputValue }) => {
        setFieldValue('mobile', inputValue);
        setFieldValue('countryCode', country.iso2);
      },
      disableDialCodeAndPrefix: true,
      disableCountryGuess: true,
      disableDialCodePrefill: true,
    });

  return (
    <Box flexDirection="row">
      <Box
        position="relative"
        w="100%"
        flexDirection="column"
        alignItems="stretch"
        className={className}
      >
        <Typography
          lineHeight="100%"
          fontSize="fontSize50"
          fontWeight="semibold"
          textTransform="uppercase"
          mb={2}
          color={hasError ? colors.messaging.error.dark : labelColor}
          data-qaid={`${qaId}-label`}
        >
          {label}
          {isRequired ? '*' : ''}
        </Typography>
        <Box alignItems="center">
          <Grid alignItems="center">
            <GridItem mobileStart={1} row={1} mobile={12}>
              <TextInput
                value={inputValue}
                name="mobile"
                type="tel"
                ref={inputRef}
                disabled={isDisabled}
                hasError={hasError}
                onBlur={handleBlur}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handlePhoneValueChange(e)
                }
                backgroundColor={inputBackgroundColor}
                pl={paddingLeft}
                invertColors={invertColors}
                aria-required={isRequired}
                data-qaid={`${qaId}-input`}
                {...props}
              />
            </GridItem>
            <GridItem
              mobileStart={1}
              mobile={12}
              row={1}
              position="absolute"
              left={`${ruler[4]}px`}
              alignItems="center"
              borderRadius={borderRadius.input}
              gap={1}
              ref={dropDownAndPrefixRef}
              h={dimensions.input.height}
            >
              <CountrySelector
                countries={countries}
                hideDropdown={false}
                selectedCountry={country.iso2}
                onSelect={({ iso2 }: { iso2: CountryIso2 }) => setCountry(iso2)}
                style={countrySelectorStyle}
                buttonStyle={buttonStyle}
                buttonContentWrapperStyle={buttonContentWrapperStyle}
                dropdownStyleProps={dropdownStyleProps}
                flagStyle={flagStyle}
                dropdownArrowStyle={dropdownArrowStyle}
              />
              <DialCodePreview
                dialCode={country.dialCode}
                prefix="+"
                style={dialCodeStyleProps}
              />
            </GridItem>
          </Grid>
        </Box>
        {(helperText || errorMsg) && (
          <InputHelperText
            isDisabled={isDisabled}
            hasError={hasError}
            isSuccessful={isSuccessful}
            invertColors={invertColors}
            data-qaid={`${qaId}-helper-text`}
          >
            {errorMsg ? errorMsg : helperText}
          </InputHelperText>
        )}
      </Box>
    </Box>
  );
};
