import { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';

export interface WithZIndexProps {
  zIndex?: number;
}

interface WithZIndexCSSProps extends WithZIndexProps {
  theme: CommonTheme;
}

export const withZIndexCSS = ({ zIndex }: WithZIndexCSSProps) => css`
  ${zIndex && `z-index: ${zIndex};`}
`;
