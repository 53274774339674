import React from 'react';
import { ApolloProvider } from 'react-apollo';

import apolloClient from './sofarApolloClient';

const SofarApolloProvider: React.FC = ({ children }) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default SofarApolloProvider;
