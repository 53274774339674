import React from 'react';
import styled, { css } from 'styled-components';

import {
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';

export interface DividerProps
  extends React.ComponentPropsWithoutRef<'div'>,
    WithSpacingProps {
  color?: string;
}

export const Divider = styled.div<DividerProps>`
  ${({ theme, color }) => css`
    border-bottom: 0;
    border-top: 1px solid ${color || theme.colors.primary.black.base};
    width: 100%;

    ${withSpacingCSS};
  `}
`;
