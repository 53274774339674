import { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';

// Pass in a color and this will add a 1px border on the side(s) you specify
export interface WithBorderProps {
  b?: string; // border (global)
  bt?: string; // border top
  br?: string; // border right
  bb?: string; // border bottom
  bl?: string; // border left
  bw?: string; // border width
  borderRadius?: string; // border radius
  borderRadiusTopLeft?: string;
  borderRadiusTopRight?: string;
  borderRadiusBottomLeft?: string;
  borderRadiusBottomRight?: string;
}

interface WithBorderCSSProps extends WithBorderProps {
  theme: CommonTheme;
}

export const withBorderCSS = ({
  b,
  bt,
  br,
  bb,
  bl,
  bw = '1px',
  borderRadius,
  borderRadiusTopLeft,
  borderRadiusTopRight,
  borderRadiusBottomLeft,
  borderRadiusBottomRight,
}: WithBorderCSSProps) => css`
  ${b == 'none' && 'border: none;'}
  ${b && `border: ${bw} solid ${b}`};
  ${bt && `border-top: ${bw} solid ${bt}`};
  ${br && `border-right: ${bw} solid ${br}`};
  ${bb && `border-bottom: ${bw} solid ${bb}`};
  ${bl && `border-left: ${bw} solid ${bl}`};
  ${borderRadiusTopLeft && `border-top-left-radius: ${borderRadiusTopLeft}`};
  ${borderRadiusTopRight && `border-top-right-radius: ${borderRadiusTopRight}`};
  ${
    borderRadiusBottomLeft &&
    `border-bottom-left-radius: ${borderRadiusBottomLeft}`
  };
  ${
    borderRadiusBottomRight &&
    `border-bottom-right-radius: ${borderRadiusBottomRight}`
  };

  ${borderRadius && `border-radius: ${borderRadius}`};
`;
