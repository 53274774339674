import { commonTheme } from './common';
import { adminManualCSSTheme, manualCSSTheme } from './manualCSS';

// Maps theme name (which we use as an alias in code so we don't have to import the theme
// itself everywhere) to the theme object itself
export const themeCatalog = {
  common: commonTheme,
  manualCSS: manualCSSTheme,
  adminManualCSS: adminManualCSSTheme,
};
