import React, { useContext, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { AuthContext } from 'app/shared/context/Auth';

interface Props {
  children: React.ReactNode;
}

interface IntlContextType {
  currentLanguage: {
    key: string;
    name: string;
  };
  setLanguage: (key: string) => void;
  options: object;
}

export const availableLanguages = {
  en: 'English',
};

const defaultLanguage = 'en';

const messages = {
  en: '',
};

export const IntlContext = React.createContext<IntlContextType>({
  currentLanguage: {
    key: defaultLanguage,
    name: availableLanguages[defaultLanguage],
  },
  setLanguage: () => {},
  options: {},
});

const Intl: React.FC<Props> = ({ children }) => {
  const { user, loggedIn } = useContext(AuthContext);
  const defaultLang = (loggedIn && user && user.locale) || 'en';
  const [language, setLanguage] = useState<string>(defaultLang);

  const onChangeLanguage = (key: string) => {
    if (availableLanguages.hasOwnProperty(key)) {
      return setLanguage(key);
    }

    return setLanguage('en');
  };

  const contextValue = {
    currentLanguage: {
      key: language,
      name: availableLanguages[language],
    },
    setLanguage: onChangeLanguage,
    options: availableLanguages,
  };

  return (
    <IntlContext.Provider value={contextValue}>
      <IntlProvider locale={language} messages={messages[language]}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

export default Intl;
