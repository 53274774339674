import { css } from 'styled-components';

import { Colors, CommonTheme } from 'app/shared/theme';
import { withChildSVGStyleCSS } from 'app/shared/components/styleUtils';

export interface WithLinkStyleProps {
  display?: string;
  isActive?: boolean;
  controlChildSVGStyle?: boolean;
  svgFillColor?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  messageType?: keyof Colors['messaging'];
}

interface WithLinkStyleCSSProps extends WithLinkStyleProps {
  theme: CommonTheme;
}

/**
 * Generates CSS styles for a link element with customizable properties.
 *
 * @param {Object} props - The properties for the link style.
 * @param {CommonTheme} props.theme - The theme object.
 * @param {string} [props.textTransform='none'] - The text transformation style.
 * @param {string} [props.textDecoration='underline'] - The text decoration style.
 * @param {string} [props.display='inline-block'] - The display style.
 * @param {('primary' | 'secondary' | 'tertiary')} [props.variant='secondary'] - The link variant.
 *    - primary = gold
 *    - secondary = black
 *    - tertiary = white
 * @param {keyof Colors['messaging']} [props.messageType] - Indicates that the link is part of text rendered in a messaging color.
 *    - If present, the link color will be set to the relevant messaging color, regardless of variant.
 * @param {boolean} [props.isActive=false] - Indicates if the link is active.
 * @param {boolean} [props.controlChildSVGStyle=true] - Indicates if the child SVG style should be controlled.
 *    - If true, the child SVG colors will be the same as the link colors
 *    - Should be set to false when wrapping a component in a link, i.e. a CompactEventCard
 *    - Should be set to true (default) when the link contents are just text + an icon
 * @return {string} The generated CSS styles for the link element.
 */
export const withLinkStyleCSS = ({
  theme,
  display = 'inline-block',
  isActive = false,
  variant = 'secondary',
  controlChildSVGStyle = true,
  svgFillColor,
  messageType,
}: WithLinkStyleCSSProps) => css`
  color: ${theme.colors.link[variant].default};
  background: none;
  border: none !important;
  outline: none !important;
  cursor: pointer;
  transition: all 400ms;
  display: ${display};

  ${
    controlChildSVGStyle &&
    withChildSVGStyleCSS({
      svgFillColor: svgFillColor || theme.colors.link[variant].default,
      svgStrokeColor: theme.colors.link[variant].default,
    })
  };

  &:focus {
    color: ${theme.colors.link[variant].focus};
    background: none;
    ${
      controlChildSVGStyle &&
      withChildSVGStyleCSS({
        svgFillColor: svgFillColor || theme.colors.link[variant].focus,
        svgStrokeColor: theme.colors.link[variant].focus,
      })
    };
  }
  &:active {
    color: ${theme.colors.link[variant].active};
    background: none;
    ${
      controlChildSVGStyle &&
      withChildSVGStyleCSS({
        svgFillColor: svgFillColor || theme.colors.link[variant].active,
        svgStrokeColor: theme.colors.link[variant].active,
      })
    };
  }
  ${
    isActive &&
    `
    color: ${theme.colors.link[variant].active};
    background: none;
    ${
      controlChildSVGStyle &&
      withChildSVGStyleCSS({
        svgFillColor: svgFillColor || theme.colors.link[variant].active,
        svgStrokeColor: theme.colors.link[variant].active,
      })
    };
  `
  }
  @media (hover: hover) {
    &:hover {
      color: ${theme.colors.link[variant].hover};
      background: none;
      ${
        controlChildSVGStyle &&
        withChildSVGStyleCSS({
          svgFillColor: svgFillColor || theme.colors.link[variant].hover,
          svgStrokeColor: theme.colors.link[variant].hover,
        })
      };
    }
  }

  ${
    messageType &&
    css`
      color: ${theme.colors.messaging[messageType].dark};
      &:focus,
      &:active,
      &:hover {
        color: ${theme.colors.messaging[messageType].dark};
      }
    `
  }
`;
