import Cookies from 'js-cookie';

const UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_id',
];
const REFERRER_PARAM = 'referrer';
const ATTRIBUTION_TIMESTAMP_PARAM = 'attribution_timestamp';
const TEN_YEARS_FROM_NOW = new Date(Date.now() + 315360000000); // 10 * 365 * 24 * 60 * 60 * 1000

interface UTMParamsMap {
  [utmParam: string]: string | null;
}

function setCurrentAndOriginalCookies({
  cookieName,
  cookieValue,
  skipOriginalCookie,
}: {
  cookieName: string;
  cookieValue: string | null;
  skipOriginalCookie: boolean;
}) {
  if (cookieValue) {
    Cookies.set(`current_${cookieName}`, cookieValue, {
      expires: TEN_YEARS_FROM_NOW,
    });
    if (!skipOriginalCookie) {
      Cookies.set(`original_${cookieName}`, cookieValue, {
        expires: TEN_YEARS_FROM_NOW,
      });
    }
  } else {
    Cookies.remove(`current_${cookieName}`);
  }
}

function getUTMParamsMap(searchParams: URLSearchParams): UTMParamsMap {
  return UTM_PARAMS.reduce((accumulator, utmParam) => {
    return { ...accumulator, [utmParam]: searchParams.get(utmParam) };
  }, {});
}

function getAttributionTimestamp(searchParams: URLSearchParams) {
  const attributionTimestampOverride = searchParams.get(
    ATTRIBUTION_TIMESTAMP_PARAM
  ); //  override can be passed to persist Attribution Data from a previous session, like during a Password Reset

  if (attributionTimestampOverride) {
    const overrideAttributionTimestamp = new Date(attributionTimestampOverride);
    if (
      overrideAttributionTimestamp instanceof Date &&
      !isNaN(overrideAttributionTimestamp.valueOf())
    ) {
      return overrideAttributionTimestamp.toString();
    }
  }
  return new Date().toString();
}

function getReferrer(searchParams: URLSearchParams) {
  let referrer = null;

  const attributionReferrerOverride = searchParams.get('attribution_referrer'); // Override can be passed to persist Attribution Data from a previous session, like during a Password Reset
  if (attributionReferrerOverride) {
    referrer = attributionReferrerOverride;
  } else {
    referrer = document.referrer;
  }
  if (referrer.includes('sofarsounds.com')) {
    referrer = null;
  }
  return referrer;
}

export function setAttributionCookies(searchParams: URLSearchParams) {
  const utmParamsMap = getUTMParamsMap(searchParams);
  const referrer = getReferrer(searchParams);

  const utmParamsWerePassedIn = Object.values(utmParamsMap).some(
    (utmParamValue) => !!utmParamValue
  );

  if (!!referrer || utmParamsWerePassedIn) {
    const originalCookiesExist =
      !!Cookies.get('original_referrer') ||
      UTM_PARAMS.some((utmParam) => {
        if (Cookies.get(`original_${utmParam}`)) {
          return true;
        }
        return false;
      });

    Object.entries(utmParamsMap).forEach(([utmParam, utmParamValue]) => {
      setCurrentAndOriginalCookies({
        cookieName: utmParam,
        cookieValue: utmParamValue,
        skipOriginalCookie: originalCookiesExist,
      });
    });

    setCurrentAndOriginalCookies({
      cookieName: REFERRER_PARAM,
      cookieValue: referrer,
      skipOriginalCookie: originalCookiesExist,
    });

    setCurrentAndOriginalCookies({
      cookieName: ATTRIBUTION_TIMESTAMP_PARAM,
      cookieValue: getAttributionTimestamp(searchParams),
      skipOriginalCookie: originalCookiesExist,
    });
  }
}
