import React from 'react';

import { NavigationLink as Link } from 'app/shared/components/atoms';
import { ActionLink } from 'app/shared/components/atoms';

export const ChildMenuItem = ({ childData, parentIndex, childIndex }: any) => {
  if (childData.display == false) {
    return null;
  }

  if (childData.link) {
    return (
      <Link
        key={childData.link}
        href={childData.link}
        onClick={childData.onClick}
        openInNewTab={childData.openInNewTab}
        textDecoration="none"
        variant="tertiary"
      >
        {childData.title}
      </Link>
    );
  }

  return (
    <ActionLink
      key={`${parentIndex}-${childIndex}`}
      onClick={childData.onClick}
      textDecoration="none"
      variant="tertiary"
    >
      {childData.title}
    </ActionLink>
  );
};
