import { Colors } from 'app/shared/theme/common/typings';

export const colors: Colors = {
  primary: {
    black: {
      base: '#1C1C1C',
      light: '#333333',
      lighter: '#606060',
      lightest: '#8D8D8D',
    },
    white: {
      base: '#FAF2DE',
      light: '#FCF6E8',
      lighter: '#FDF9EF',
      lightest: '#FFFFFF',
    },
  },
  button: {
    primary: {
      background: '#D1A03F',
      text: '#1C1C1C',
      altText: '#606060',
      hover: '#DFBD79',
      active: '#DFBD79',
      focus: '#DFBD79',
      disabled: '#8D8D8D',
    },
    secondary: {
      background: 'transparent',
      text: '#1C1C1C',
      altText: '#606060',
      hover: ' #606060',
      active: ' #606060',
      focus: ' #606060',
      disabled: '#8D8D8D',
    },
    tertiary: {
      background: 'transparent',
      text: '#D1A03F',
      altText: '#606060',
      hover: ' #DFBD79',
      active: ' #DFBD79',
      focus: ' #DFBD79',
      disabled: '#8D8D8D',
    },
  },
  link: {
    primary: {
      default: '#D1A03F',
      hover: '#DFBD79',
      active: '#DFBD79',
      focus: '#DFBD79',
    },
    secondary: {
      default: '#1C1C1C',
      hover: '#333333',
      active: '#333333',
      focus: '#333333',
    },
    tertiary: {
      default: '#FAF2DE',
      hover: '#FFFFFF',
      active: '#FFFFFF',
      focus: '#FFFFFF',
    },
  },
  inputBorder: {
    default: '#FAF2DE',
    hover: '#D1A03F',
    focus: '#D1A03F',
    inverted: '#333333',
    invertedHover: '#606060',
    invertedFocus: '#606060',
  },
  accent: {
    primaryAccent: {
      darkest: '#B88D37',
      dark: '#B88D37',
      base: '#D1A03F',
      light: '#D6AA52',
      lightest: '#DFBD79',
    },
  },
  messaging: {
    success: {
      light: '#80FF80',
      dark: '#005900',
    },
    info: {
      light: '#80C8FF',
      dark: '#00508C',
    },
    error: {
      light: '#FF8080',
      dark: '#A60000',
    },
    warning: {
      light: '#FFEA80',
      dark: '#664400',
    },
  },
};
