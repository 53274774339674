import gql from 'graphql-tag';

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile(
    $email: String
    $firstName: String
    $lastName: String
    $homeCityId: String
    $birthYear: String
    $mobile: MobileAttributes
    $optInFanMarketingSmses: Boolean
    $optOutFanMarketingEmails: Boolean
  ) {
    updateUserProfile(
      email: $email
      firstName: $firstName
      lastName: $lastName
      homeCityId: $homeCityId
      birthYear: $birthYear
      mobile: $mobile
      optInFanMarketingSmses: $optInFanMarketingSmses
      optOutFanMarketingEmails: $optOutFanMarketingEmails
    ) {
      success
      user {
        id
        email
        mobile {
          countryCodeIso3166
          numberBody
        }
      }
    }
  }
`;
