import { useContext } from 'react';

import { AnalyticsContext } from 'app/shared/context/Analytics';

export const useAnalyticsContext = () => {
  const value = useContext(AnalyticsContext);

  if (value === null) {
    throw new Error(
      'useAnalyticsContext must be called within a child or grandchild of an Analytics Context Provider!'
    );
  }
  return value;
};
