import React, { useContext } from 'react';
import loadable from '@loadable/component';

import {
  CommonGlobalStyle,
  CommonTheme,
  ManualCSSGlobalStyle,
  ManualCSSTheme,
} from 'app/shared/theme';
import { themeCatalog } from 'app/shared/theme/themeCatalog';
import { FeatureFlagsContext } from 'app/shared/context/FeatureFlags';
import ThemeWrapper from 'app/shared/components/atoms/ThemeWrapper';

const AlertProvider = loadable(() => import('app/shared/context/Alert'));
const NotifyProvider = loadable(() => import('app/shared/context/Notify'));

export type ThemeNameOrFunction =
  | string
  | ((enabledFeatureFlags: string[]) => string);

const validGlobalStyleNames = ['CommonGlobalStyle', 'ManualCSSGlobalStyle'];

function getEnabledFeatureFlags(featureFlagsEnabled?: {}): string[] {
  return featureFlagsEnabled
    ? Object.keys(featureFlagsEnabled).filter(
        (flag: string) => !!featureFlagsEnabled[flag]
      )
    : [];
}

function getThemeName(
  themeNameOrFunction: ThemeNameOrFunction,
  enabledFeatureFlags: string[]
): string {
  if (typeof themeNameOrFunction === 'string') {
    return themeNameOrFunction;
  } else {
    return themeNameOrFunction(enabledFeatureFlags);
  }
}

function validateThemeNameExists(themeName?: string) {
  if (!themeName) {
    throw new Error(
      "withTheme: Couldn't determine a themeName - themeNameOrFunction didn't return a theme"
    );
  }

  return null;
}

function validateThemeNameInThemeCatalog(themeName: string) {
  if (!themeCatalog[themeName]) {
    throw new Error(
      "withTheme: Couldn't find themeName in themeCatalog - all themes must be listed in themeCatalog"
    );
  }

  return null;
}

function validateThemeHasGlobalStyleName(theme: CommonTheme | ManualCSSTheme) {
  if (!theme.globalStyleName) {
    throw new Error(
      `withTheme: The ${theme.name} theme is missing a globalStyleName property - all themes must set a globalStyleName`
    );
  }

  if (!validGlobalStyleNames.includes(theme.globalStyleName)) {
    throw new Error(
      `withTheme: The ${theme.name} theme has an invalid globalStyleName property (${theme.globalStyleName}) = see validGlobalStyleNames`
    );
  }

  return null;
}

const withTheme = (
  PassedComponent: React.ComponentType<any>,
  themeNameOrFunction: ThemeNameOrFunction
): React.FC => {
  return (props: any) => {
    const featureFlagsContext = useContext(FeatureFlagsContext);

    const enabledFeatureFlags = getEnabledFeatureFlags(
      featureFlagsContext.featureFlagsEnabled
    );

    const themeName = getThemeName(themeNameOrFunction, enabledFeatureFlags);

    validateThemeNameExists(themeName);
    validateThemeNameInThemeCatalog(themeName);

    const themeToUse = themeCatalog[themeName];

    validateThemeHasGlobalStyleName(themeToUse);

    if (
      featureFlagsContext.queryRequired &&
      (!featureFlagsContext.called ||
        featureFlagsContext.loading ||
        featureFlagsContext.error)
    ) {
      return <div data-qaid="loading-page"></div>;
    }

    const isManualCSSTheme =
      themeToUse.globalStyleName === 'ManualCSSGlobalStyle';
    const MessagingProvider = isManualCSSTheme ? NotifyProvider : AlertProvider;
    const GlobalStyleComponent = isManualCSSTheme
      ? ManualCSSGlobalStyle
      : CommonGlobalStyle;

    return (
      <ThemeWrapper themeName={themeName}>
        <GlobalStyleComponent theme={themeToUse} />
        <MessagingProvider>
          <PassedComponent {...props} />
        </MessagingProvider>
      </ThemeWrapper>
    );
  };
};

export default withTheme;
