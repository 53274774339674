import gql from 'graphql-tag';

import { SESSION_USER_FIELDS } from 'app/shared/graphql/sessions/queries';

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile(
    $email: String
    $firstName: String
    $lastName: String
    $homeCityId: String
    $birthYear: String
    $mobile: MobileAttributes
    $marketingEmailStatus: String
    $optInFanMarketingSmses: Boolean
  ) {
    updateUserProfile(
      email: $email
      firstName: $firstName
      lastName: $lastName
      homeCityId: $homeCityId
      birthYear: $birthYear
      mobile: $mobile
      marketingEmailStatus: $marketingEmailStatus
      optInFanMarketingSmses: $optInFanMarketingSmses
    ) {
      success
      user {
        ...SessionUserFields
      }
    }
  }
  ${SESSION_USER_FIELDS}
`;
