import React, { createContext, useContext, useMemo } from 'react';

import { Permission } from 'app/shared/typings/permissions';
import { AuthContext } from 'app/shared/context/Auth';

interface Props {
  children: any;
}

interface PermissionsContextValues {
  permissions: Permission[] | undefined;
  permissionsLoaded: boolean;
  hasPermission: (name: string, id?: number) => boolean;
}

export const PermissionsContext = createContext<PermissionsContextValues>({
  permissions: undefined,
  permissionsLoaded: false,
  hasPermission: () => false,
});

const PermissionsProvider: React.FC<Props> = ({ children }) => {
  const { loading, permissions } = useContext(AuthContext);

  const contextValue = useMemo(() => {
    const permissionsLoaded = !loading && permissions !== undefined;

    function hasPermission(name: string, id?: number) {
      if (!permissionsLoaded) {
        return false;
      }

      let hasPerm = false;

      if (permissions) {
        for (const permission of permissions) {
          if (permission.name == name) {
            if (!permission.resourceId || !id) {
              hasPerm = true;
              break;
            } else if (id && id.toString() === permission.resourceId) {
              hasPerm = true;
              break;
            }
          }
        }
      }

      if (process.env.LOG_PERMISSION_TESTS === 'true') {
        const resourceStr = id ? ` (resource ${id})` : '';
        // eslint-disable-next-line no-console
        console.log(
          `-----hasPermission called with ${name}${resourceStr}, returned ${hasPerm}`
        );
      }

      return hasPerm;
    }

    return {
      permissions,
      permissionsLoaded,
      hasPermission,
    };
  }, [permissions, loading]);

  return (
    <PermissionsContext.Provider value={contextValue}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsProvider;
