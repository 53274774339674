import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { Typography } from 'app/shared/components/atoms';

export const ModalTitle: React.FC<{
  title: string;
  fontSize?: string;
  disableClose?: boolean;
  'data-qaid'?: string;
}> = ({
  title,
  fontSize = 'fontSize500',
  'data-qaid': qaId,
  disableClose = false,
}) => {
  const { fonts } = useCurrentTheme();
  return (
    <Typography
      data-qaid={qaId}
      fontSize={fontSize}
      fontWeight="medium"
      fontFamily={fonts.title}
      lineHeight="120%"
      mr={disableClose ? 0 : [5, 5, 8, 8]}
    >
      {title}
    </Typography>
  );
};
