import { useCallback } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

export const useSegmentPage = (
  analytics: AnalyticsBrowser,
  setEventsCalled: (events: string[]) => void
) => {
  return useCallback(
    (title: string) => {
      analytics.page(title, {
        nonInteraction: 1,
      });
      setEventsCalled([]);
    },
    [analytics]
  );
};
