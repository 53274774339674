import { matchPath, useLocation } from 'react-router-dom';
import { defaultRouteConfig, routesConfig } from 'src/routesConfig';

export const useRouteInfoForCurrentPath = () => {
  const location = useLocation();

  return (
    routesConfig.find(
      (routeInfo: any) =>
        // @ts-ignore
        matchPath(
          {
            path: routeInfo.path,
            exact: true,
            strict: false,
          },
          location.pathname
        ) != null
    ) || defaultRouteConfig
  );
};
