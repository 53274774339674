import { css } from 'styled-components';

import { BreakPointName, CommonTheme } from 'app/shared/theme';

type SpacingProp = number | 'auto';

export interface WithSpacingProps {
  // global margin & padding
  m?: SpacingProp | SpacingProp[];
  p?: SpacingProp | SpacingProp[];

  // horizontal margin & padding
  mx?: SpacingProp | SpacingProp[];
  px?: SpacingProp | SpacingProp[];

  // vertical margin & padding
  my?: SpacingProp | SpacingProp[];
  py?: SpacingProp | SpacingProp[];

  // margin top, right, bottom, left
  mt?: SpacingProp | SpacingProp[];
  mr?: SpacingProp | SpacingProp[];
  mb?: SpacingProp | SpacingProp[];
  ml?: SpacingProp | SpacingProp[];

  // padding top, right, bottom, left
  pt?: SpacingProp | SpacingProp[];
  pr?: SpacingProp | SpacingProp[];
  pb?: SpacingProp | SpacingProp[];
  pl?: SpacingProp | SpacingProp[];
}

interface WithSpacingCSSProps extends WithSpacingProps {
  theme: CommonTheme;
}

function generateCssProperty(spacingPropName: string, value: string) {
  const typeMapping: { [key: string]: string } = {
    m: 'margin',
    p: 'padding',
  };

  const [type, sideOrAxis] = spacingPropName.split('');

  const sideOrAxisMapping: { [key: string]: string } = {
    t: 'top',
    r: 'right',
    b: 'bottom',
    l: 'left',
    x: 'inline',
    y: 'block',
  };

  const cssProp = sideOrAxisMapping[sideOrAxis]
    ? `${typeMapping[type]}-${sideOrAxisMapping[sideOrAxis]}`
    : `${typeMapping[type]}`;

  return `${cssProp}: ${value};`;
}

function generateSpace(
  theme: CommonTheme,
  screenSize: BreakPointName,
  spacingPropName: string,
  size?: SpacingProp | SpacingProp[]
) {
  if (Array.isArray(size)) {
    let styles: string[] = [];
    const sizeIndex = Object.keys(theme.breakPoints).indexOf(screenSize);

    if (sizeIndex >= 0) {
      const sizeForBreakpoint = size[sizeIndex];

      if (sizeForBreakpoint || sizeForBreakpoint === 0) {
        if (sizeForBreakpoint === 'auto') {
          styles.push(generateCssProperty(spacingPropName, 'auto'));
        } else {
          styles.push(
            generateCssProperty(
              spacingPropName,
              `${theme.ruler[sizeForBreakpoint]}px`
            )
          );
        }
      }
    }

    return styles;
  }

  if (size === 'auto') {
    return generateCssProperty(spacingPropName, 'auto');
  }

  return generateCssProperty(spacingPropName, `${theme.ruler[Number(size)]}px`);
}

function generateCSSForScreenSize(
  screenSize: BreakPointName,
  withSpacingProps: WithSpacingCSSProps
) {
  let s = '';

  Object.keys(withSpacingProps).forEach((spacingPropName) => {
    if (/^(m|p)(t|b|r|l|x|y)?$/.test(spacingPropName)) {
      s += generateSpace(
        withSpacingProps.theme,
        screenSize,
        spacingPropName,
        withSpacingProps[spacingPropName as keyof WithSpacingProps]
      );
    }
  });

  return css`
    ${s}
  `;
}

export const withSpacingCSS = (props: WithSpacingCSSProps) => css`
  ${generateCSSForScreenSize('mobile', props)};

  ${props.theme.media.tablet`
    ${generateCSSForScreenSize('tablet', props)};
  `}

  ${props.theme.media.smallDesktop`
    ${generateCSSForScreenSize('smallDesktop', props)};
  `}

  ${props.theme.media.largeDesktop`
    ${generateCSSForScreenSize('largeDesktop', props)};
  `}
`;
