import { css } from 'styled-components';

import { CommonTheme, FontSizeValue, FontWeightType } from 'app/shared/theme';

export interface WithFontProps {
  fontSize?: FontSizeValue;
  fontWeight?: FontWeightType;
  fontStyle?: string;
  fontFamily?: string;
  lineHeight?: string;
  letterSpacing?: string;
  textTransform?: string;
  textDecoration?: string;
  textAlign?: string;
  textWrap?: string;
  textOverflow?: string;
}

interface WithFontCSSProps extends WithFontProps {
  theme: CommonTheme;
}

function responsiveFontSizeCSS({ theme, fontSize }: WithFontCSSProps) {
  if (fontSize) {
    const fontSizeResponsive = theme.fontSizes[fontSize];

    if (fontSizeResponsive) {
      return css`
        ${theme.media.mobile`
              font-size: ${fontSizeResponsive.mobile};
            `};

        ${theme.media.smallDesktop`
              font-size: ${fontSizeResponsive.desktop};
            `};
      `;
    }
  }

  return css`
    ${theme.media.mobile`
        font-size: ${theme.fontSizes.root.mobile};
      `};

    ${theme.media.smallDesktop`
        font-size: ${theme.fontSizes.root.desktop};
      `};
  `;
}

export const withFontCSS = ({
  theme,
  fontSize = 'fontSize100',
  fontWeight = 'regular',
  fontStyle = 'normal',
  fontFamily,
  lineHeight,
  letterSpacing,
  textTransform = 'none',
  textDecoration = 'none',
  textAlign = 'unset',
  textWrap = 'unset',
  textOverflow = 'unset',
}: WithFontCSSProps) => css`
  ${responsiveFontSizeCSS({ theme, fontSize })}
  font-family: ${fontFamily || theme.fonts.regular} !important;

  text-transform: ${textTransform};
  text-decoration: ${textDecoration};
  text-align: ${textAlign};
  text-wrap: ${textWrap};
  text-overflow: ${textOverflow};

  letter-spacing: ${
    letterSpacing
      ? letterSpacing
      : textTransform === 'uppercase'
        ? theme.letterSpacings.uppercase
        : theme.letterSpacings.default
  };
  word-wrap: break-word;

  font-weight: ${theme.fontWeights[fontWeight]};

  font-style: ${fontStyle};

  line-height: ${lineHeight || theme.fontSizes.rootLineHeight};

  -webkit-font-smoothing: antialiased;
`;
