import { useMutation } from '@apollo/client';

import {
  UpdateUserProfileData,
  UpdateUserProfileVariables,
  UpdateUserSmsOptInVariables,
} from 'app/shared/typings/users';

import { UPDATE_USER_PROFILE } from './mutations';

export const UpdateUserProfile = () => {
  return useMutation<
    UpdateUserProfileData,
    UpdateUserSmsOptInVariables | UpdateUserProfileVariables
  >(UPDATE_USER_PROFILE)[0];
};
