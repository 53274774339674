import React, { createContext, useContext, useMemo, useState } from 'react';

import { AuthFormType } from 'app/shared/typings';

export interface NavbarContextValue {
  currentTabSelected: AuthFormType;
  setCurrentTabSelected: React.Dispatch<React.SetStateAction<AuthFormType>>;
  isAuthModalOpen: boolean;
  setIsAuthModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLandingPage?: boolean;
}

export interface NavbarContextProps {
  children?: React.ReactNode;
  isLandingPage?: boolean;
}

export const NavbarContext = createContext<NavbarContextValue | null>(null);

export const NavbarContextProvider: React.FC<NavbarContextProps> = ({
  children,
  isLandingPage = false,
}) => {
  const [currentTabSelected, setCurrentTabSelected] =
    useState<AuthFormType>('signUp');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const navbarContextValue = useMemo(
    () => ({
      currentTabSelected,
      setCurrentTabSelected,
      isAuthModalOpen,
      setIsAuthModalOpen,
      isMobileMenuOpen,
      setIsMobileMenuOpen,
      isLandingPage,
    }),
    [currentTabSelected, isAuthModalOpen, isMobileMenuOpen, isLandingPage]
  );

  return (
    <NavbarContext.Provider value={navbarContextValue}>
      {children}
    </NavbarContext.Provider>
  );
};

export function useNavbarContext(): NavbarContextValue {
  const authModalContextValue = useContext(NavbarContext);
  if (authModalContextValue === null) {
    throw new Error(
      'useNavbarContext must be called within a child or grandchild of a Navbar Context Provider'
    );
  }
  return authModalContextValue;
}
