import { css } from 'styled-components';

import {
  ManualCSSTheme,
  MediaManualCSS,
  TShirtSizeBreakpointName,
  TShirtSizeBreakpoints,
} from 'app/shared/theme/manualCSS/typings';

// breakpoints define the minimum width of a device (>=)
const breakPoints: TShirtSizeBreakpoints = {
  xs: 0,
  sm: 576,
  // md had been 768 but widened to support additional nav items.
  // TODO: Revisit after nav rethink.
  md: 886,
  lg: 1052,
  xl: 1200,
};

export const adminManualCSSTheme: ManualCSSTheme = {
  name: 'adminManualCSS',
  globalStyleName: 'ManualCSSGlobalStyle',

  isFanFacing: false,

  breakPoints,

  fonts: {
    regular: 'Open Sans',
    primaryFallback: 'sans-serif',
  },

  colors: {
    // TODO: finalize these colors
    primary: '#039842',
    primaryActive: '#039842',
    primaryHover: '#006721',
    primaryText: '#FFFFFF',
    secondaryText: '#039842',
    invertedSecondaryText: '#FFFFFF',
    disabledText: '#FFFFFF',
    disabledBackground: '#949494',
    darkBorder: '#949494',
    focusedBackground: 'DDBEE026',

    // blacks & greys
    black: '#000000',
    backToBlack: '#111111',
    blackBetty: '#1E1E1E',
    paintItBlack: '#333333',
    darkSmoke: '#767676',
    blueSmoke: '#949494',
    lightBlueSmoke: '#94949499',
    macyGrey: '#DBDBDB',
    darkGrey: '#535353',
    silverSprings: '#F5F5F5',
    whiteDenim: '#FFFFFF',
    warmGrey: '#A29992',

    // green variations
    green900: '#006721',
    green800: '#008736',
    green700: '#039842',
    green600: '#10AD52',
    green500: '#23BA57',
    green400: '#4FC571',
    green300: '#72D08A',
    green200: '#9DDCAC',
    green100: '#CFEACC',
    green50: '#E6F7EA',

    // additional
    greenOnions: '#8BC34B',
    greenGarden: '#19BC9B',
    blueChristmas: '#3498DB',
    purpleHaze: '#3F51B5',
    purplePills: '#9B59B6',
    checkBerry: '#98035A',
    blueMagic: '#34495E',
    blueGrey: '#a7bdd4',
    brownSugar: '#9B525F',
    pinkTriangle: '#E91E63',
    royalBlood: '#FF5000',
    redRedWine: '#EF4423',
    peaches: '#F99973',
    orangeCrush: '#F39C12',
    mellowYellow: '#F1C410',
    yellowSubmarine: '#FFD426',
  },
  fontSizes: {
    base: { lg: '16px', xs: '16px' },
    title: { lg: '54px', md: '38px', xs: '26px' },
    h1: { lg: '48px', md: '34px', xs: '24px' },
    h2: { lg: '34px', md: '24px', xs: '20px' },
    h3: { lg: '24px', md: '20px', xs: '16px' },
    h4: { lg: '20px', md: '16px', xs: '16px' },
    h5: { lg: '16px', md: '14px', xs: '14px' },
    h6: { lg: '14px', md: '14px', xs: '14px' },
    bodyTitle: { lg: '24px', md: '16px', xs: '16px' },
    body1: { lg: '16px', xs: '16px' },
    body2: { lg: '14px', xs: '14px' },
    overline: { lg: '10px', xs: '10px' },
    caption: { lg: '10px', xs: '10px' },
    button: { lg: '14px', xs: '14px' },
    formGroupLabel: '14px',
    tooltip: '10px',
    selectOption: '16px',
  },

  fontColors: {
    primary: '#000000',
    secondary: '#000000',
  },

  fontStyles: {
    links: {
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    title: {
      letterSpacing: '-2px',
    },
    h1: {
      lineHeight: 'normal',
      textTransform: 'none',
      letterSpacing: '-1.5px',
    },
    h2: {
      lineHeight: 'normal',
      textTransform: 'none',
      letterSpacing: '-0.5px',
    },
    h3: {
      lineHeight: 'normal',
      textTransform: 'none',
      letterSpacing: 'normal',
    },
    h4: {
      lineHeight: 'normal',
      textTransform: 'none',
      letterSpacing: '0.3px',
    },
    h5: {
      lineHeight: 'normal',
      textTransform: 'none',
      letterSpacing: 'normal',
    },
    h6: {
      lineHeight: 'normal',
      textTransform: 'none',
      letterSpacing: '0.2px',
    },
    body: {
      lineHeight: '1.75',
      letterSpacing: '0.4px',
    },
    body2: {
      lineHeight: '1.43',
      letterSpacing: '0.3px',
    },
    caption: {
      lineHeight: '1.6',
      letterSpacing: '0.3px',
    },
    formGroupLabel: {
      letterSpacing: '0.1px',
    },
    button: {
      fontWeight: '600',
    },
  },
  media: Object.keys(breakPoints).reduce((acc, label: string) => {
    acc[label as keyof TShirtSizeBreakpoints] = (...args: any) => css`
      @media (min-width: ${breakPoints[label as TShirtSizeBreakpointName]}px) {
        ${css.apply(undefined, args)};
      }
    `;
    return acc;
  }, {} as MediaManualCSS),

  ruler: Array.from({ length: 28 }, (_e, i) => i * 4),
  zIndex: {
    navbar: 100,
  },

  dimensions: {
    buttonHeight: '44px',
    radioBox: '20px',
    containerWidth: {
      small: '600px',
      default: '800px',
      large: '1000px',
      fullscreen: '100%',
    },

    button: {
      small: {
        height: '40px',
        padding: '0 15px',
      },

      default: {
        height: '44px',
        padding: '0 25px',
      },
    },

    navbarHeight: {
      lg: '84px',
      md: '74px',
      xs: '62px',
      xsNoCitySelector: '62px',
      mdNoCitySelector: '74px',
    },

    container: {
      padding: '0 16px',
      paddingSm: '0 10px',
      maxWidthSm: '540px',
      maxWidthMd: '740px',
      maxWidthLg: '960px',
      maxWidthXl: '1160px',
      innerMargin: 'auto',
    },

    menuItem: {
      padding: '8px 16px',
    },

    input: {
      height: '44px',
    },
  },

  borderRadius: {
    default: '2px',
    large: '8px',
    button: '4px',
    buttonSmall: '4px',
    input: '2px',
  },

  utils: {
    transition: (attr?: string) => `${attr || 'all'} .2s ease`,
  },
};
