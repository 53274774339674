import React, { useCallback, useContext } from 'react';
import { useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';

import { useAnalyticsContext } from 'app/shared/utils';
import { useInferredUserCountryCode } from 'app/shared/utils/useInferredUserCountryCode';
import { UpdateUserSmsOptInVariables, User } from 'app/shared/typings';
import { UpdateUserProfile } from 'app/shared/graphql/users/mutationHooks';
import { AuthContext } from 'app/shared/context/Auth';
import { shouldShowSmsOptIn } from 'app/fan/utils/shouldShowSmsOptIn';

import { useAuthFormContext } from './AuthForm';

interface SmsMarketingSignUpFormValues {
  onSmsSignUpSubmit: (props: OnSignUpSubmitProps) => void;
  initialFormValues: any;
  shouldDisplaySmsOptIn: boolean;
}

interface SmsMarketingSignUpFormProps {
  formContext: string;
  checkSmsPopupCookie: boolean;
  checkIsAccountOlderThanThirtyDays: boolean;
  hide?: boolean;
  onSuccess?: () => void;
}
type SmsMarketingSignUpForm = (
  args: SmsMarketingSignUpFormProps
) => SmsMarketingSignUpFormValues;

interface MultiStepSignUpContextValue {
  newUser?: User;
  setNewUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  smsMarketingSignUpForm: SmsMarketingSignUpForm;
  success: boolean;
  setSuccess: (value: boolean) => void;
  inferredUserCountryCode?: string;
  inferredCountryIsTopGlobalCountry: boolean;
}

export const MultiStepSignUpContext =
  React.createContext<MultiStepSignUpContextValue | null>(null);

interface OnSignUpSubmitProps {
  smsFormData: {
    firstName?: string;
    lastName?: string;
    mobile: string;
    countryCode: string;
  };
  setSubmitting: (value: boolean) => void;
}

export const MultiStepSignUpContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [newUser, setNewUser] = useState<User | undefined>();
  const { user: loggedInUser } = useContext(AuthContext);
  const user = loggedInUser || newUser;

  const { inferredUserCountryCode, inferredCountryIsTopGlobalCountry } =
    useInferredUserCountryCode({ user });

  const [success, setSuccess] = useState(false);
  const { setApiError } = useAuthFormContext();
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const updateUserProfile = UpdateUserProfile();

  const smsMarketingSignUpForm = useCallback(
    ({
      formContext,
      checkSmsPopupCookie,
      checkIsAccountOlderThanThirtyDays,
      hide = false,
      onSuccess,
    }: SmsMarketingSignUpFormProps) => {
      const initialFormValues = {
        countryCode: inferredUserCountryCode || '',
        mobile: user?.mobile?.numberBody || '',
      };

      const shouldDisplaySmsOptIn = shouldShowSmsOptIn({
        user,
        inferredCountryIsTopGlobalCountry,
        checkSmsPopupCookie,
        checkIsAccountOlderThanThirtyDays,
        hide,
      });

      const onSmsSignUpSubmit = async ({
        smsFormData,
        setSubmitting,
      }: OnSignUpSubmitProps) => {
        setSubmitting(true);

        const analyticsProps = {
          form_context: formContext,
          is_standalone_form: true,
          default_country: inferredUserCountryCode,
          mobile: smsFormData.mobile,
          country_code: smsFormData.countryCode,
        };

        const variables: UpdateUserSmsOptInVariables = {
          mobile: {
            numberBody: smsFormData.mobile,
            countryCode: smsFormData.countryCode.toUpperCase(),
          },
          optInFanMarketingSmses: true,
        };

        try {
          const response = await updateUserProfile({
            variables,
          });

          if (response?.data?.updateUserProfile?.success) {
            setSubmitting(false);
            setSuccess(true);
            setApiError(undefined);
            trackAnalyticsEvent('SMS Opt-In Succeeded', analyticsProps);
            onSuccess && onSuccess();
          } else {
            trackAnalyticsEvent('SMS Opt-In Failed', {
              ...analyticsProps,
              error: undefined,
            });
            setSubmitting(false);
          }
        } catch (e) {
          const apolloError = e as ApolloError;
          setApiError(apolloError);
          trackAnalyticsEvent('SMS Opt-In Failed', {
            ...analyticsProps,
            error: apolloError.message,
          });
          setSubmitting(false);
        }
      };

      return {
        onSmsSignUpSubmit,
        initialFormValues,
        shouldDisplaySmsOptIn,
      };
    },
    [user, newUser, inferredUserCountryCode, inferredCountryIsTopGlobalCountry]
  );

  const multiStepSignUpContextValue = useMemo(
    () => ({
      newUser,
      setNewUser,
      smsMarketingSignUpForm,
      success,
      setSuccess,
      inferredUserCountryCode,
      inferredCountryIsTopGlobalCountry,
    }),
    [
      newUser,
      inferredUserCountryCode,
      inferredCountryIsTopGlobalCountry,
      smsMarketingSignUpForm,
      success,
      setSuccess,
    ]
  );

  return (
    <MultiStepSignUpContext.Provider value={multiStepSignUpContextValue}>
      {children}
    </MultiStepSignUpContext.Provider>
  );
};

export function useMultiStepSignUpContext(): MultiStepSignUpContextValue {
  const multipStepSignUpContext = React.useContext(MultiStepSignUpContext);

  if (multipStepSignUpContext === null) {
    throw new Error(
      'useMultiStepSignUpContext must be used within a MultiStepSignUpContextProvider'
    );
  }

  return multipStepSignUpContext;
}
