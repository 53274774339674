import React, { useContext, useEffect } from 'react';

import {
  City,
  CityForClp,
  ModalStep,
  OnSignUpSuccess,
  RenderCompetitionOptIn,
} from 'app/shared/typings';
import {
  useAuthFormContext,
  useMultiStepSignUpContext,
} from 'app/shared/context';
import { AuthContext } from 'app/shared/context/Auth';
import { StepWizard } from 'app/shared/components/molecules';

import AccountStep from './AccountStep';
import SmsStep from './SmsStep';

interface Props {
  formContext: string;
  userEmail?: string;
  city?: City | CityForClp;
  smsMarketingSignUpTitle?: string;
  createAccountTitle?: string;
  stepOne?: React.ReactNode;
  activeStep: number;
  modalStep: ModalStep;
  setModalStep: React.Dispatch<React.SetStateAction<ModalStep>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  onSignUpSuccess?: OnSignUpSuccess;
  onSuccess?: OnSignUpSuccess;
  onClickSignIn?: () => void;
  renderCompetitionOptIn?: RenderCompetitionOptIn;
  competitionDataSharingOptIn?: boolean;
  renderModal?: boolean;
  testMode?: boolean;
  testResponse?: any;
}

export const MultiStepSignUpWizard: React.FC<
  React.PropsWithChildren<Props>
> = ({
  formContext,
  smsMarketingSignUpTitle,
  stepOne,
  activeStep,
  modalStep,
  setModalStep,
  isLoading,
  setIsLoading,
  isOpen,
  setIsOpen,
  onClose,
  onSuccess: onSuccessProp,
  competitionDataSharingOptIn = false,
  renderModal = true,
  testMode,
  testResponse,
  ...props
}) => {
  const { newUser, smsMarketingSignUpForm } = useMultiStepSignUpContext(); // Trigger error if not a descendant of MultiStepSignUpContextProvider
  const { user: loggedInUser, signIn } = useContext(AuthContext);
  const { isCompleteSignUpFlow } = useAuthFormContext();
  const user = loggedInUser || newUser;

  function onSuccess() {
    setIsLoading(true);
    setIsOpen && setIsOpen(false);
    signIn();
    onSuccessProp &&
      user &&
      onSuccessProp({
        user,
        competitionDataSharingOptIn,
      });
  }

  const { shouldDisplaySmsOptIn } = smsMarketingSignUpForm({
    formContext,
    onSuccess,
    checkSmsPopupCookie: false,
    checkIsAccountOlderThanThirtyDays: false,
  });

  useEffect(() => {
    setIsLoading(true);

    if (!!user && shouldDisplaySmsOptIn && !isCompleteSignUpFlow) {
      setModalStep(ModalStep.SMS_OPT_IN);
    } else if (user) {
      onSuccess();
    }

    setIsLoading(false);
  }, [user]);

  const accountStep = (
    <AccountStep
      formContext={formContext}
      onClose={onClose}
      setIsOpen={setIsOpen}
      testMode={testMode}
      testResponse={testResponse}
      {...props}
    />
  );

  const smsStep = (
    <SmsStep
      title={smsMarketingSignUpTitle}
      onSuccess={onSuccess}
      onClose={onClose}
      setIsOpen={setIsOpen}
      formContext={formContext}
    />
  );

  const qaId = `${formContext}-wizard-step-${activeStep}`;

  if (!stepOne) {
    return (
      <StepWizard
        data-qaid={qaId}
        stepOne={accountStep}
        stepTwo={smsStep}
        setIsOpen={setIsOpen}
        activeStep={activeStep}
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={onClose}
        maxWidth="480px"
        renderModal={renderModal}
      />
    );
  }

  return (
    <StepWizard
      data-qaid={qaId}
      disableClose={modalStep === ModalStep.TICKET_INFO}
      stepOne={stepOne}
      stepTwo={accountStep}
      stepThree={smsStep}
      setIsOpen={setIsOpen}
      activeStep={activeStep}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      maxWidth="480px"
      renderModal={renderModal}
    />
  );
};
