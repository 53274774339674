import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ApolloError } from '@apollo/client';

import { getValidationErrors } from 'app/shared/utils/useSubmitAction';

interface AuthFormContextProviderProps {
  isCheckoutAuth: boolean;
  isCompleteSignUpFlow?: boolean;
  isPlusOneFlow?: boolean;
}

export interface AuthFormContextValue {
  isCheckoutAuth: boolean;
  isCompleteSignUpFlow: boolean;
  isPlusOneFlow: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  isSubmitting: boolean;
  apiError?: ApolloError;
  setApiError: (error?: ApolloError) => void;
  validationErrors: { [key: string]: string };
  setValidationErrors: Dispatch<SetStateAction<{ [key: string]: string }>>;
  thirdPartyAuthProvider?: 'Apple' | 'Facebook' | 'Google';
  setThirdPartyAuthProvider: (
    provider: 'Apple' | 'Facebook' | 'Google'
  ) => void;
}

export const AuthFormContext = createContext<AuthFormContextValue | null>(null);

export const AuthFormContextProvider: React.FC<
  React.PropsWithChildren<AuthFormContextProviderProps>
> = ({
  isCheckoutAuth,
  isCompleteSignUpFlow = false,
  isPlusOneFlow = false,
  children,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<ApolloError | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const [thirdPartyAuthProvider, setThirdPartyAuthProvider] = useState<
    'Apple' | 'Facebook' | 'Google' | undefined
  >();

  useEffect(() => {
    if (apiError) {
      setValidationErrors(getValidationErrors(apiError) || {});
    } else {
      setValidationErrors({});
    }
  }, [apiError]);

  const authFormContextValue = useMemo(
    () => ({
      isSubmitting,
      setIsSubmitting,
      isCheckoutAuth,
      isCompleteSignUpFlow,
      isPlusOneFlow,
      apiError,
      setApiError,
      validationErrors,
      setValidationErrors,
      thirdPartyAuthProvider,
      setThirdPartyAuthProvider,
    }),
    [
      isCheckoutAuth,
      isCompleteSignUpFlow,
      isPlusOneFlow,
      isSubmitting,
      apiError,
      validationErrors,
      thirdPartyAuthProvider,
    ]
  );

  return (
    <AuthFormContext.Provider value={authFormContextValue}>
      {children}
    </AuthFormContext.Provider>
  );
};

export const useAuthFormContext = () => {
  const context = React.useContext(AuthFormContext);
  if (context === null) {
    throw new Error(
      'useAuthFormContext must be used within a AuthFormContextProvider'
    );
  }
  return context;
};
