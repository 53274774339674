import React, { useContext } from 'react';

import { AuthContext } from 'app/shared/context/Auth';
import { PermissionsContext } from 'app/shared/context/Permissions';
import ExternalRedirect from 'app/shared/components/atoms/ExternalRedirect';

const withPermissionsCheck = (
  PassedComponent: React.ComponentType<any>,
  permissionName?: string
) => {
  // Optional permissionName param indicates that an entire page requires a permission check
  return (props: any) => {
    const { loggedIn } = useContext(AuthContext);
    const { hasPermission, permissionsLoaded } = useContext(PermissionsContext);

    if (!loggedIn) {
      return <PassedComponent {...props} />;
    }

    if (permissionName) {
      if (!permissionsLoaded) {
        return <div data-qaid="loading-permissions"></div>;
      } else if (hasPermission(permissionName)) {
        return <PassedComponent {...props} />;
      } else {
        return <ExternalRedirect to="/dashboard?authorize_msg=1" />;
      }
    }

    return <PassedComponent {...props} />;
  };
};

export default withPermissionsCheck;
