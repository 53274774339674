import React from 'react';
import styled from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import { useLinkClickBehavior } from 'app/shared/utils/useLinkClickBehavior';
import {
  withDimensionsCSS,
  WithDimensionsProps,
} from 'app/shared/components/styleUtils/withDimensionsCSS';
import {
  withFlexCSS,
  WithFlexProps,
} from 'app/shared/components/styleUtils/withFlexCSS';
import {
  withFontCSS,
  WithFontProps,
} from 'app/shared/components/styleUtils/withFontCSS';
import {
  withLinkStyleCSS,
  WithLinkStyleProps,
} from 'app/shared/components/styleUtils/withLinkStyleCSS';
import {
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils/withSpacingCSS';

export interface NavigationLinkProps
  extends React.ComponentPropsWithoutRef<'a'>,
    WithSpacingProps,
    WithFlexProps,
    WithDimensionsProps,
    WithFontProps,
    React.AriaAttributes {
  openInNewTab?: boolean;
  display?: string;
}

interface LinkCSSProps extends NavigationLinkProps, WithLinkStyleProps {
  theme: CommonTheme;
}

// Keep withFlexCSS after withLinkStyleCSS
const StyledAnchor = styled.a<LinkCSSProps>`
  ${withDimensionsCSS};
  ${withSpacingCSS};
  ${withFontCSS};
  ${withLinkStyleCSS};
  ${withFlexCSS};
`;

export const NavigationLink: React.FC<
  NavigationLinkProps & WithLinkStyleProps
> = ({
  openInNewTab = false,
  children,
  href,
  onClick,
  target,
  rel,
  ...props
}) => {
  const { anchorRef, handleClick, newRel, newTarget } = useLinkClickBehavior({
    openInNewTab,
    href,
    onClick,
    target,
    rel,
  });

  return (
    <StyledAnchor
      textDecoration="underline"
      textTransform="none"
      {...props}
      as="a"
      ref={anchorRef}
      onClick={handleClick}
      rel={newRel}
      target={newTarget}
      href={href}
    >
      {children}
    </StyledAnchor>
  );
};
