import React from 'react';

function withBeamer(PassedComponent: React.ComponentType<any>) {
  const WrappedComponent: React.FC = (props: any) => {
    const enableBeamer = !!process.env.BEAMER_ID;

    if (enableBeamer) {
      window.beamer_config = {
        lazy: true,
        mobile: true,
        product_id: `${process.env.BEAMER_ID}`,
        right: -9,
        selector: '#beamerButton',
      };

      const script = document.createElement('script');
      script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
      script.async = false;
      script.defer = true;
      script.type = 'text/javascript';
      document.head.appendChild(script);
    }
    return <PassedComponent {...props} />;
  };

  return WrappedComponent;
}

export default withBeamer;
