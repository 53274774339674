import { CommonTheme } from 'app/shared/theme/common/typings';

import { borderRadius } from './borderRadius';
import { boxShadow } from './boxShadow';
import { breakPoints, media } from './breakPoints';
import { colors } from './colors';
import { dimensions } from './dimensions';
import { fonts, fontSizes, fontWeights, letterSpacings } from './fonts';
import { zIndex } from './zIndex';

export const commonTheme: CommonTheme = {
  name: 'common',
  globalStyleName: 'CommonGlobalStyle',
  breakPoints,
  media,
  ruler: Array.from({ length: 100 }, (_e, i) => i * 4),
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  colors,
  dimensions,
  borderRadius,
  boxShadow,
  zIndex,
};
