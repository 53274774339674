import { css } from 'styled-components';

import { BreakPointName, CommonTheme } from 'app/shared/theme';

export interface WithSpacingProps {
  // global margin & padding
  m?: number | number[];
  p?: number | number[];

  // horizontal margin & padding
  mx?: number | number[];
  px?: number | number[];

  // vertical margin & padding
  my?: number | number[];
  py?: number | number[];

  // margin top, right, bottom, left
  mt?: number | number[];
  mr?: number | number[];
  mb?: number | number[];
  ml?: number | number[];

  // padding top, right, bottom, left
  pt?: number | number[];
  pr?: number | number[];
  pb?: number | number[];
  pl?: number | number[];
}

interface WithSpacingCSSProps extends WithSpacingProps {
  theme: CommonTheme;
}

function generateCssProperty(spacingPropName: string, value: string) {
  const typeMapping = {
    m: 'margin',
    p: 'padding',
  };

  const [type, sideOrAxis] = spacingPropName.split('');

  if (sideOrAxis == 'x') {
    return `
      ${typeMapping[type]}-right: ${value};
      ${typeMapping[type]}-left: ${value};
    `;
  }

  if (sideOrAxis == 'y') {
    return `
      ${typeMapping[type]}-top: ${value};
      ${typeMapping[type]}-bottom: ${value};
    `;
  }

  const sideMapping = {
    t: 'top',
    r: 'right',
    b: 'bottom',
    l: 'left',
  };

  const cssProp = sideMapping[sideOrAxis]
    ? `${typeMapping[type]}-${sideMapping[sideOrAxis]}`
    : `${typeMapping[type]}`;

  return `${cssProp}: ${value};`;
}

function generateSpace(
  theme: CommonTheme,
  screenSize: BreakPointName,
  spacingPropName: string,
  size: any
) {
  if (Array.isArray(size)) {
    let styles: any = [];
    const sizeIndex = Object.keys(theme.breakPoints).indexOf(screenSize);

    if (sizeIndex >= 0) {
      if (size[sizeIndex] || size[sizeIndex] === 0) {
        styles.push(
          generateCssProperty(
            spacingPropName,
            `${theme.ruler[Number(size[sizeIndex])]}px`
          )
        );
      }
    }

    return styles;
  }

  return generateCssProperty(spacingPropName, `${theme.ruler[Number(size)]}px`);
}

function generateCSSForScreenSize(
  screenSize: BreakPointName,
  withSpacingProps: WithSpacingCSSProps
) {
  let s = '';

  Object.keys(withSpacingProps).forEach((spacingPropName: string) => {
    if (/^(m|p)(t|b|r|l|x|y)?$/.test(spacingPropName)) {
      s += generateSpace(
        withSpacingProps.theme,
        screenSize,
        spacingPropName,
        withSpacingProps[spacingPropName]
      );
    }
  });

  return css`
    ${s}
  `;
}

export const withSpacingCSS = (props: WithSpacingCSSProps) => css`
  ${generateCSSForScreenSize('mobile', props)};

  ${props.theme.media.tablet`
    ${generateCSSForScreenSize('tablet', props)};
  `}

  ${props.theme.media.smallDesktop`
    ${generateCSSForScreenSize('smallDesktop', props)};
  `}

  ${props.theme.media.largeDesktop`
    ${generateCSSForScreenSize('largeDesktop', props)};
  `}
`;
