const invalidBusinessAreaDirectoryErrorMsg = (
  businessAreaDir: string,
  componentPath: string
) =>
  `RenderRoutes: Invalid business area directory '${businessAreaDir}' for component path ${componentPath} - ` +
  `business area directory can only be one of: ${validBusinessAreaDirs.join(
    '|'
  )}`;

const invalidBusinessAreaDirectorySimpleErrorMsg = (businessAreaDir: string) =>
  `RenderRoutes: Invalid business area directory '${businessAreaDir}'`;

const invalidPageDirectoryErrorMsg = (pageDir: string, componentPath: string) =>
  `RenderRoutes: Invalid page directory '${pageDir}' for component path ${componentPath} - ` +
  "page directory can only be a one-level dir e.g. 'Event', not multiple levels of dir e.g. 'Event/Upcoming'";

const validBusinessAreaDirs = ['admin', 'artist', 'fan', 'shared', 'venue'];

const isValidBusinessArea = (businessArea: string) =>
  validBusinessAreaDirs.includes(businessArea);

const isValidPageDir = (pageDir: string) => !pageDir.includes('/');

const getBusinessArea = (componentPath: string) => {
  // Returns e.g. 'artist'
  return componentPath.replace('app/', '').replace(/\/.+$/, '');
};

const getPageDir = (componentPath: string) => {
  // Returns e.g. 'Home'
  return componentPath.replace('app/', '').replace(/^.+\//, '');
};

const getPagePathInfo = (componentPath: string) => {
  const businessArea = getBusinessArea(componentPath);

  if (!isValidBusinessArea(businessArea)) {
    throw new Error(
      invalidBusinessAreaDirectoryErrorMsg(businessArea, componentPath)
    );
  }

  const pageDir = getPageDir(componentPath);

  if (!isValidPageDir(pageDir)) {
    throw new Error(invalidPageDirectoryErrorMsg(pageDir, componentPath));
  }

  return {
    businessArea,
    pageDir,
  };
};

export const getRouteImport = (componentPath: string) => {
  const { businessArea, pageDir } = getPagePathInfo(componentPath);

  // We literally must write out each top-level dir under */pages/ below, e.g. we can't store the list
  // in a constant and loop over it - additionally, pageDir must be only a one-level dir e.g. 'foo', it can't
  // be multiple levels of dir e.g. 'foo/bar' - this is all b/c of how rollup dynamic imports work:
  //   https://www.npmjs.com/package/rollup-plugin-dynamic-import-variables
  //
  // i.e. b/c of how vite performs static analysis, there are several constraints on how we can interpolate
  // Javascript variables inside of a string value passed to the 'import' method:
  // - path must begin with './'
  // - path must end with filename including extension e.g. 'index.tsx'
  // - a few other constraints, see link above
  //
  // We chose to leave the leading 'app/' on the componentPath values in routesConfig purely to make
  // it easier for humans to quickly grok where the page component lives just by looking in routesConfig

  switch (businessArea) {
    case 'admin':
      return import(`./app/admin/components/pages/${pageDir}/index.tsx`);
    case 'artist':
      return import(`./app/artist/components/pages/${pageDir}/index.tsx`);
    case 'fan':
      return import(`./app/fan/components/pages/${pageDir}/index.tsx`);
    case 'shared':
      return import(`./app/shared/components/pages/${pageDir}/index.tsx`);
    case 'venue':
      return import(`./app/venue/components/pages/${pageDir}/index.tsx`);
    default:
      throw new Error(invalidBusinessAreaDirectorySimpleErrorMsg(businessArea));
  }
};
