import gql from 'graphql-tag';

export const CREATE_ACCOUNT = gql`
  mutation createAccount(
    $firstName: String
    $lastName: String
    $email: String!
    $mobile: MobileAttributes
    $optInFanMarketingSmses: Boolean
    $optOutFanMarketingEmails: Boolean
    $password: String!
    $citySlug: String
    $reCaptchaToken: String
  ) {
    createAccount(
      firstName: $firstName
      lastName: $lastName
      email: $email
      optOutFanMarketingEmails: $optOutFanMarketingEmails
      optInFanMarketingSmses: $optInFanMarketingSmses
      mobile: $mobile
      password: $password
      citySlug: $citySlug
      reCaptchaToken: $reCaptchaToken
    ) {
      sessionId
      isNewAccount
      user {
        id
        isGuest
        email
        mobile {
          countryCodeIso3166
          numberBody
        }
        optInFanMarketingSmses
        homeCity {
          id
          country {
            id
            countryCode
          }
        }
      }
    }
  }
`;

export const CREATE_APPLE_SESSION = gql`
  mutation createAppleSession(
    $idToken: String!
    $firstName: String
    $lastName: String
    $matchingEmail: String
    $isGuestCheckoutOrderComplete: Boolean
  ) {
    createAppleSession(
      idToken: $idToken
      firstName: $firstName
      lastName: $lastName
      matchingEmail: $matchingEmail
      isGuestCheckoutOrderComplete: $isGuestCheckoutOrderComplete
    ) {
      sessionId
      isNewAccount
      user {
        id
        isGuest
        email
        mobile {
          countryCodeIso3166
          numberBody
        }
        optInFanMarketingSmses
        homeCity {
          id
          country {
            id
            countryCode
          }
        }
      }
    }
  }
`;

export const CREATE_FACEBOOK_SESSION = gql`
  mutation createFacebookSession(
    $fbToken: String!
    $citySlug: String
    $matchingEmail: String
    $isGuestCheckoutOrderComplete: Boolean
  ) {
    createFacebookSession(
      fbToken: $fbToken
      citySlug: $citySlug
      matchingEmail: $matchingEmail
      isGuestCheckoutOrderComplete: $isGuestCheckoutOrderComplete
    ) {
      sessionId
      isNewAccount
      user {
        id
        isGuest
        email
        mobile {
          countryCodeIso3166
          numberBody
        }
        optInFanMarketingSmses
        homeCity {
          id
          country {
            id
            countryCode
          }
        }
      }
    }
  }
`;

export const CREATE_GOOGLE_SESSION = gql`
  mutation createGoogleSession(
    $accessToken: String!
    $matchingEmail: String
    $isGuestCheckoutOrderComplete: Boolean
  ) {
    createGoogleSession(
      accessToken: $accessToken
      matchingEmail: $matchingEmail
      isGuestCheckoutOrderComplete: $isGuestCheckoutOrderComplete
    ) {
      sessionId
      isNewAccount
      user {
        id
        isGuest
        email
        mobile {
          countryCodeIso3166
          numberBody
        }
        optInFanMarketingSmses
        homeCity {
          id
          country {
            id
            countryCode
          }
        }
      }
    }
  }
`;

export const CREATE_SESSION = gql`
  mutation createSession(
    $email: String!
    $password: String!
    $isGuestCheckoutOrderComplete: Boolean
  ) {
    createSession(
      email: $email
      password: $password
      isGuestCheckoutOrderComplete: $isGuestCheckoutOrderComplete
    ) {
      sessionId
      isNewAccount
      user {
        id
        isGuest
        email
        mobile {
          countryCodeIso3166
          numberBody
        }
        optInFanMarketingSmses
        homeCity {
          id
          country {
            id
            countryCode
          }
        }
      }
    }
  }
`;

export const DESTROY_SESSION = gql`
  mutation destroySession {
    destroySession {
      success
    }
  }
`;

export const UPDATE_SESSION = gql`
  mutation updateSession($hideBannerMessage: Boolean) {
    updateSession(hideBannerMessage: $hideBannerMessage) {
      success
    }
  }
`;
