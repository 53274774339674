export const usePixelTracker = () => {
  const trackPixel = (eventDescription: string, analyticsProperties: any) => {
    if (
      eventDescription == 'Checkout Complete' &&
      !!window?.uet_report_conversion_purchase
    ) {
      window.uet_report_conversion_purchase(analyticsProperties);
      if (
        analyticsProperties.country == 'US' &&
        !!window?.mntn_conversion_purchase
      ) {
        window.mntn_conversion_purchase(analyticsProperties);
      }
    }
    if (
      (eventDescription == 'Sign Up Form Modal Account Created' ||
        eventDescription == 'Account Sign Up Success') &&
      !!window?.uet_report_conversion_signup
    ) {
      window.uet_report_conversion_signup(analyticsProperties);
    }
  };

  return trackPixel;
};
