import Cookies from 'js-cookie';

import { isOlderThanThirtyDays } from 'app/shared/utils/datetime';
import { User } from 'app/shared/typings';

interface Props {
  user?: User;
  inferredCountryIsTopGlobalCountry: boolean;
  checkSmsPopupCookie: boolean;
  checkIsAccountOlderThanThirtyDays: boolean;
  hide?: boolean;
}

export function shouldShowSmsOptIn({
  user,
  inferredCountryIsTopGlobalCountry,
  checkSmsPopupCookie,
  checkIsAccountOlderThanThirtyDays,
  hide = false,
}: Props) {
  const { smsPopupDismissed } = Cookies.get();

  const doNotDisplaySmsForm =
    !user ||
    !user.shouldPromptForSmsMarketing ||
    !inferredCountryIsTopGlobalCountry ||
    (checkSmsPopupCookie && smsPopupDismissed == 'true') ||
    (checkIsAccountOlderThanThirtyDays &&
      !isOlderThanThirtyDays(user.createdAt)) ||
    hide;

  return doNotDisplaySmsForm ? false : true;
}
