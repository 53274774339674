import { createGlobalStyle, css } from 'styled-components';

import { ManualCSSTheme } from './typings';

export const ManualCSSGlobalStyle = createGlobalStyle`
  ${({ theme }: { theme: ManualCSSTheme }) => css`
    body,
    html,
    * {
      font-family: ${theme.fonts.regular}, ${theme.fonts.primaryFallback},
        sans-serif;
    }

    body {
      color: ${theme.colors.backToBlack};
      margin: 0px;

      ${theme.media.xs`
        font-size: ${theme.fontSizes.base.xs};
      `};

      ${theme.media.lg`
        font-size: ${theme.fontSizes.base.lg};
      `};
    }

    html {
      box-sizing: border-box;
    }

    *,
    ::after,
    ::before {
      box-sizing: inherit;
    }

    a {
      color: ${theme.colors.backToBlack};
      text-decoration: ${theme.fontStyles.links.textDecoration};
    }

    a:link,
    a:visited {
      text-decoration: none;
    }

    button {
      background: none;
      margin-left: 0px;
      margin-right: 0px;
    }

    .grecaptcha-badge {
      visibility: hidden;
    }
  `}
`;
